import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from '../api';
import { DEFAULT_SETTINGS, Settings } from './settings.interface';

/**
 * Settings Service
 *
 * Service to handle figuring out site settings.
 */
export class SettingsService {
  static instance: SettingsService;

  static getInstance(): SettingsService {
    if (!SettingsService.instance) {
      SettingsService.instance = new SettingsService();
    }
    return SettingsService.instance;
  }

  private _settings$: BehaviorSubject<any> = new BehaviorSubject<Settings>(DEFAULT_SETTINGS);
  get settings$(): Observable<Settings> {
    return this._settings$.asObservable();
  }
  get settings(): Settings {
    return this._settings$.value;
  }

  /**
   * Uses the Api Service to get the settings.json and save the response.
   * Uses the DEFAULT_SETTINGS if an error occurs or as fallback values if the json is missing any values.
   *
   * @returns The returned settings.
   */
  getSettings(): Observable<Settings> {
    return ApiService.getInstance()
      .getSettingsJson()
      .pipe(
        map((settingsJson: Settings) => {
          const newSettings: Settings = { ...DEFAULT_SETTINGS, ...settingsJson };
          this._settings$.next(newSettings);
          return newSettings;
        }),
        catchError(() => {
          this._settings$.next(DEFAULT_SETTINGS);
          return of(DEFAULT_SETTINGS);
        }),
      );
  }
}
