import React, { ChangeEvent, Component, MouseEvent } from 'react';
import { forkJoin, Observable, Subject } from 'rxjs';
import { take, takeUntil, takeWhile } from 'rxjs/operators';
import { CSVLink } from 'react-csv';
import { Headers, LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactDOM from 'react-dom';

import {
  ApiService,
  GetStationMeasurementsByIdParams,
  MagstarMeasurement,
  MagstarShortStationInfo,
  StationsService
} from '../../services';
import { Utils } from '../../shared';
import { StartEndDateFormComponent, StartEndDateOnFormChange } from '../start-end-date-form/start-end-date-form.component';

// Same as MagstarMeasurement, but timestamp is a string
export interface DownloadData {
  name?: string;
  timestamp: string;
  x: number;
  y: number;
  z: number;
  temperature: number;
  horizontal_field_angle: number;
  horizontal_field_magnitude: number;
}

export interface DownloadFormState {
  unsubscribe$: Subject<void>;
  availableStations: MagstarShortStationInfo[];
  form: {
    radioValue: number;
    startDate: number;
    endDate: number;
    selectedStationId: number;
  };
}

/**
 * DOWNLOAD FORM COMPONENT
 *
 * The download form is accessible from the control bar. It provides a way to download the data for one (1) or more stations
 * for a specified range of time.
 */
export class DownloadFormComponent extends Component<unknown, DownloadFormState> {
  private readonly _headers: Headers = [
    { label: 'Timestamp', key: 'timestamp' },
    { label: 'X', key: 'x' },
    { label: 'Y', key: 'y' },
    { label: 'Z', key: 'z' },
    { label: 'Declination', key: 'horizontal_field_angle' },
    { label: 'Horizontal Field Magnitude', key: 'horizontal_field_magnitude' },
    { label: 'Temperature', key: 'temperature' },
  ];
  private readonly _oneFileHeaders: Headers = [{ label: 'Name', key: 'name' }, ...(this._headers as LabelKeyObject[])];

  private _isMounted = false;

  constructor(props: unknown) {
    super(props);
    this.state = {
      unsubscribe$: new Subject<void>(),
      availableStations: StationsService.getInstance().stations || [],
      form: {
        radioValue: 24,
        startDate: undefined,
        endDate: undefined,
        selectedStationId: undefined,
      }
    };
  }

  /**
   * On component mount:
   *  - grab the list of available stations
   */
  componentDidMount(): void {
    this._isMounted = true;
    this.getStations();
  }

  /**
   * On component unmount, complete any active subscriptions.
   */
  componentWillUnmount(): void {
    this._isMounted = false;
    Utils.completeSubject(this.state.unsubscribe$);
  }


  handleRadioInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const stationId: number = +event.target.value;

    this.setState({...this.state, form: {...this.state.form, selectedStationId: stationId}});
  }

  /**
   * Sets the radio button, start time, and end time values.
   *
   * @param form The form values.
   */
  handleFormChange = (form: StartEndDateOnFormChange): void => {
    if (this._isMounted) {
      this.setState({
        ...this.state,
        form: { ...this.state.form, radioValue: form.radioValue, startDate: form.startDate, endDate: form.endDate },
      });
    }
  };

  /**
   * Gets all the available stations to populate the list of selectable stations.
   */
  getStations = (): void => {
    StationsService.getInstance()
      .stations$.pipe(
        takeUntil(this.state.unsubscribe$),
        takeWhile(() => !this.state.availableStations.length),
      )
      .subscribe((stations: MagstarShortStationInfo[]) => {
        this.setState({ ...this.state, availableStations: stations });
      });
  };

  downloadCSV =(event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    ApiService.getInstance().causeCSVDownload(
        this.state.form.selectedStationId, this.state.form.startDate, this.state.form.endDate
    );
  };

  /**
   * Determines whether or not the download button is disabled.
   * The button is disabled when:
   *  - no radio options are selected
   *  - if the custom day radio option is selected AND the date input(s) are empty
   *  - if the custom range radio option is selected AND both the start and end date input(s) are empty
   *  - if there are NO stations selected
   *  - if the CSV is currently being downloaded/ generated.
   *
   * @returns True if the download button should be disabled, false otherwise.
   */
  isDownloadButtonDisabled = (): boolean => {
    const hasRadioValue = Boolean(this.state.form.radioValue);
    const hasStartDate: boolean = this.state.form.radioValue === -1 && Boolean(this.state.form.startDate);
    const hasEndDate: boolean = this.state.form.radioValue === -2 && Boolean(this.state.form.endDate);
    const hasStartAndEndDate: boolean = hasStartDate && hasEndDate;
    return !(hasRadioValue || hasStartDate || hasStartAndEndDate) || this.state.form.selectedStationId === undefined;
  };

  /**
   * Generates the station selection DOM element.
   *
   * @returns The generated station selection DOM element.
   */
  generateStationsSelection = (): JSX.Element => {
    return (
        <div className="gic-form__field-group">
        <h3>Stations</h3>
        <div className="available-stations">
          {this.state.availableStations.map((station: MagstarShortStationInfo) => (
            <div key={station.station_id} className="gic-input gic-input__radio">
              <input
                id={`station-radio-${station.station_id}`}
                type="radio"
                name="station_id"
                checked={this.state.form.selectedStationId == station.station_id}
                onChange={this.handleRadioInputChange}
                value={station.station_id}
              />
              <label htmlFor={`station-radio-${station.station_id}`}>{station.name || station.station_id}</label>
            </div>
          ))}
        </div>
      </div>
    )
  }


  /**
   * Generates the download DOM element.
   *
   * @returns The generated download DOM element.
   */
  generateDownloadButton = (): JSX.Element => {
    const isDisabled = this.isDownloadButtonDisabled();

    return (
      <div className="download-csv-button-wrapper">
        <button
          className={`gic-button download-csv-button ${isDisabled ? '--not-allowed-disabled' : ''}`}
          onClick={this.downloadCSV}
          disabled={isDisabled}>
          {"Download"}
        </button>
      </div>
    );
  }

  render(): JSX.Element {
    const downloadButton: JSX.Element = this.generateDownloadButton();
    const stationSelection: JSX.Element = this.generateStationsSelection();

    return (
      <form className="gic-form download-form">
        <h2>Download Magnetometer Data</h2>
        <StartEndDateFormComponent onFormChange={this.handleFormChange}></StartEndDateFormComponent>
        {stationSelection}
        {downloadButton}
      </form>
    );
  }
}
