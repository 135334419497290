import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { BreakPoint, Color, FontSize, FontWeight, Spacing } from '../../styling';

export const styling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .gic {
    &-status-box {
      align-items: center;
      background: ${theme.component.background};
      border-top: 4px solid;
      display: flex;
      flex-direction: column;
      min-width: 180px;
      padding: ${Spacing.LG};
      width: 100%;

      &--error {
        border-color: ${Color.Red};
      }

      &--warning {
        border-color: ${Color.Orange};
      }

      &--success {
        border-color: ${Color.Green};
      }

      &--unknown {
        border-color: ${Color.Dark_Grey};
      }

      & > * {
        display: block;
        text-align: center;
      }

      &__icon {
        font-size: ${FontSize.XXL};

        &--error {
          color: ${Color.Red};
        }

        &--warning {
          color: ${Color.Orange};
        }

        &--success {
          color: ${Color.Green};
        }

        &--unknown {
          color: ${Color.Dark_Grey};
        }
      }

      &__main {
        font-size: ${FontSize.XXXL};
        font-weight: ${FontWeight.Light};
      }

      &__sub {
        &-text {
          font-size: ${FontSize.MD};
        }

        &-popover-icon {
        }
      }

      &__container {
        display: flex;
        flex-wrap: no-wrap;
        flex: auto;

        & > * + * {
          border-left: 1px solid ${theme.border};
        }

        @media (max-width: ${BreakPoint.X_SMALL}) {
          flex-wrap: wrap;
        }
      }
    }
  }
`;
