import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { BreakPoint, Spacing } from '../../styling';

export const styling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .gic {
    &-dashboard {
      &__statuses {
        display: flex;

        & > * + * {
          margin: 0 0 0 ${Spacing.MD};
        }

        @media (max-width: ${BreakPoint.MEDIUM}) {
          flex-direction: column;

          & > * + * {
            margin: ${Spacing.LG} 0 0 0;
          }
        }
      }
    }
  }
`;
