import React, { Component } from 'react';

import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faQuestionCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@material-ui/core';

export enum Status {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  UNKNOWN = 'unknown',
}

export interface StatusBoxState {
  isPopoverOpen: boolean;
  popoverAnchorElement: EventTarget & SVGSVGElement;
}

export interface StatusBoxProps {
  status: Status;
  text: {
    main: string;
    sub: string;
    popover?: string;
  };
}

/**
 * STATUS BOX COMPONENT
 */
export class StatusBoxComponent extends Component<StatusBoxProps, StatusBoxState> {
  constructor(props: StatusBoxProps) {
    super(props);
    this.state = {
      isPopoverOpen: false,
      popoverAnchorElement: null,
    };
  }

  /**
   * Updates the state (popoverAnchorElement) when the icon is hovered over.
   *
   * @param event The mouse event.
   */
  handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
    this.setState({ ...this.state, popoverAnchorElement: event.currentTarget });
  };

  /**
   * Updates the state (popoverAnchorElement) when the icon is no longer hovered over.
   *
   * @param event The mouse event.
   */
  handlePopoverClose = (): void => {
    this.setState({ ...this.state, popoverAnchorElement: null });
  };

  /**
   * Generates the status icon based on the provided status.
   *
   * @returns The generated font awesome icon DOM element.
   */
  generateStatusIcon = (): JSX.Element => {
    let icon: IconDefinition;

    switch (this.props.status) {
      case Status.ERROR:
        icon = faExclamationCircle;
        break;
      case Status.WARNING:
        icon = faExclamationTriangle;
        break;
      case Status.SUCCESS:
        icon = faCheckCircle;
        break;
      case Status.UNKNOWN:
      default:
        icon = faQuestionCircle;
        break;
    }

    return (
      <FontAwesomeIcon
        icon={icon}
        className={`gic-status-box__icon gic-status-box__icon--${this.props.status || Status.UNKNOWN}`}></FontAwesomeIcon>
    );
  };

  render(): JSX.Element {
    return (
      <div className={`gic-status-box gic-status-box--${this.props.status || Status.UNKNOWN}`}>
        {this.generateStatusIcon()}
        <p className="gic-status-box__main">{this.props.text.main}</p>
        <p className="gic-status-box__sub">
          <span className="gic-status-box__sub-text">{this.props.text.sub}</span>
          {this.props.text.popover && (
            <span className="gic-status-box__sub-popover-wrapper">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="gic-status-box__sub-popover-icon"
                aria-owns={this.state.popoverAnchorElement ? 'gic-status-box__sub-popover' : undefined}
                aria-haspopup="true"
                onMouseDown={this.handlePopoverOpen}
                onMouseUp={this.handlePopoverClose}
              />
              <Popover
                id="gic-status-box__sub-popover"
                classes={{ root: 'gic-status-box__sub-popover' }}
                open={!!this.state.popoverAnchorElement}
                anchorEl={this.state.popoverAnchorElement}
                onClose={this.handlePopoverClose}
                disableRestoreFocus>
                {this.props.text.popover}
              </Popover>
            </span>
          )}
        </p>
      </div>
    );
  }
}
