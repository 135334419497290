import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { Color, Spacing } from '../../styling';

export const styling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .gic {
    &-notification {
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: ${Spacing.SM};

      &__icon {
        margin-right: ${Spacing.LG};
      }

      &--error {
        background: ${Color.Red};
        color: ${Color.White};
      }

      &--warning {
        background: ${Color.Orange};
        color: ${Color.Black};
      }

      &--success {
        background: ${Color.Green};
        color: ${Color.White};
      }

      &--info {
        background: ${Color.Light_Blue};
        color: ${Color.Black};
      }
    }
  }
`;
