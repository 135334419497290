import { Observable, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { NotificationType } from '../../components/notification/notification.component';

export interface NotificationConfig {
  type: NotificationType;
  content?: JSX.Element;
  text?: string;
  uuid?: string;
}

/**
 * NOTIFICATION SERVICE
 */
export class NotificationService {
  static instance: NotificationService;
  static getInstance(): NotificationService {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService();
    }
    return NotificationService.instance;
  }

  private _notifications: NotificationConfig[] = [];
  get notifications(): NotificationConfig[] {
    return this._notifications;
  }

  private _onChange: Subject<NotificationConfig[]> = new Subject<NotificationConfig[]>();
  onChange$: Observable<NotificationConfig[]> = this._onChange.asObservable();

  /**
   * Adds a new notification to the array of notification configs.
   *
   * @param notification The notification config used to generate and display the new notification.
   */
  add = (notification: NotificationConfig): void => {
    const newConfig: NotificationConfig = notification;
    newConfig.uuid = newConfig.uuid || uuidv4();
    this._notifications.push(newConfig);
    this._onChange.next(this._notifications);
  };

  /**
   * Removes a notification from the array of notification configs.
   * Remove the notification with the provided id; otherwise, just remove the last notification from the array.
   *
   * @param id Optional. The id of the notification to be removed.
   */
  remove = (id?: string): void => {
    if (id) {
      const matchingIndex: number = this._notifications.findIndex((n: NotificationConfig) => n.uuid === id);
      if (matchingIndex > -1) {
        this._notifications.splice(matchingIndex, 1);
      }
    } else {
      this._notifications.pop();
    }

    this._onChange.next(this._notifications);
  };
}
