import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { FontWeight, Spacing } from './styles.enum';

export const resetStyling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
  * {
    position: relative;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-weight: ${FontWeight.Light};
  }

  h2 {
    font-weight: ${FontWeight.Light};

    & + * {
      margin-top: ${Spacing.SM};
    }
  }

  h3 {
    & + * {
      margin-top: ${Spacing.XS};
    }
  }
`;
