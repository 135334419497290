import { Observable, throwError } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

import { Utils } from '../../shared';
import { AuthService } from '../auth';
import {
  GetStationMeasurementsByIdParams,
  MagstarMeasurement,
  MagstarMeasurementResult,
  MagstarShortStationInfo,
  MagstarStationInfo,
} from './api.interface';
import React from "react";

/**
 * API SERVICE
 *
 * Service to handle making calls to the backend.
 */
export class ApiService {
  static instance: ApiService;
  static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  private _baseUrl: string;
  set baseUrl(url: string) {
    this._baseUrl = url;
  }
  get baseUrl(): string {
    return this._baseUrl;
  }

  private _old_version: string;
  private _version: string;

  set old_version(version: string) {
    this._old_version = version;
  }
  get old_version(): string {
    return this._old_version;
  }

  set version(version: string) {
    this._version = version;
  }
  get version(): string {
    return this._version;
  }



  constructor() {
    this._baseUrl = `${window.location.protocol}//${window.location.host}`;
    this._version = 'v1';
    this._old_version = 'v0';
  }

  /**
   * Attempts to log in using the provided username and password.
   * If successful, sets a session cookie to be used in all subsequent API calls.
   *
   * @param username The username used to log in.
   * @param password The password used to log in.
   * @returns The result of the POST request.
   */
  login(username: string, password: string): Observable<{ token: string }> {
    return ajax
      .post(`${this._baseUrl}/${this._old_version}/jwt-login`, { username, password }, this.generateHeaders())
      .pipe(map((res: AjaxResponse) => res.response));
  }

  /**
   * Checks to see if the current browser is already logged in.
   *
   * @returns The result of the GET request.
   */
  isLoggedIn(): Observable<unknown> {
    return ajax.get(`${this._baseUrl}/${this._old_version}/jwt-login`, this.generateHeaders());
  }

  /**
   * Gets the list of stations.
   *
   * @returns The list of stations.
   */
  getStations(): Observable<MagstarShortStationInfo[]> {
    return ajax.get(`${this._baseUrl}/${this._version}/stations`, this.generateHeaders()).pipe(
      map((res: AjaxResponse) => res.response),
      catchError(this.handleError),
    );
  }

  /**
   * Gets the general information for a station with the provided id.
   * Converts any of the returned time data from seconds to milliseconds.
   *
   * @param id The id of the station.
   * @returns The general information of the station with the provided id.
   */
  getStationById(id: number): Observable<MagstarStationInfo> {
    return ajax.get(`${this._baseUrl}/${this._version}/stations/${id}`, this.generateHeaders()).pipe(
      map((res: AjaxResponse) => {
        if (res.response) {
          return {
            ...res.response,
            last_seen: Utils.convertSecondsToMilliseconds(res.response.last_seen),
            earliest_timestamp: Utils.convertSecondsToMilliseconds(res.response.last_seen),
            latest_timestamp: Utils.convertSecondsToMilliseconds(res.response.last_seen),
          };
        }
        return res.response;
      }),
      catchError(this.handleError),
    );
  }

  /**
   * Gets the measurement information for a station with the provided id within the provided parameters.
   * Converts the after_ts and before_ts time data from milliseconds to seconds.
   * Converts any fo the returned time data from seconds to milliseconds.
   *
   * @param id The id of the station.
   * @param parameters Optional. Additional options to filter and control the order of the measurements.
   * @returns The measurements of the station with the provided id.
   */
  getStationMeasurementsById(id: number, parameters?: GetStationMeasurementsByIdParams): Observable<MagstarMeasurementResult> {
    const params: string = Object.keys(parameters || {})
      .map((key: string) => {
        if (key === 'after_ts' || key === 'before_ts') {
          parameters[key] = Utils.convertMillisecondsToSeconds(parameters[key]);
        }
        return parameters[key] ? `${key}=${parameters[key]}` : undefined;
      })
      .filter((val: string) => !!val)
      .join('&');

    const baseUrl = `${this._baseUrl}/${this._version}/stations/${id}/measurements`;
    const url: string = params ? `${baseUrl}?${params}` : baseUrl;

    return ajax.get(url, this.generateHeaders()).pipe(
      map((res: AjaxResponse) => {
        if (res.response) {
          return {
            measurements: res.response.measurements?.map((measurement: MagstarMeasurement) => ({
              ...measurement,
              timestamp: Utils.convertSecondsToMilliseconds(measurement.timestamp as number),
            })),
            has_further_data: res.response.has_further_data,
            next_ts: Utils.convertSecondsToMilliseconds(res.response.next_ts),
          };
        }
        return res.response;
      }),
      catchError(this.handleError),
    );
  }

  /**
   * Gets the settings.json to use to set up the application (enable/ disable features and sections).
   *
   * @returns The settings.json object.
   */
  getSettingsJson(): Observable<any> {
    return ajax.get(`${this._baseUrl}/settings.json`, this.generateHeaders()).pipe(
      map((res: AjaxResponse) => res.response),
      catchError(this.handleError),
    );
  }

  causeCSVDownload(station_id: number, start_time: number, end_time: number): void {
    const token: string = AuthService.getInstance().jwt;

    const hiddenForm = document.createElement('form');
    hiddenForm.method = "POST";
    hiddenForm.target = "_blank";
    hiddenForm.action = `/${this._old_version}/measurements-download`
    hiddenForm.innerHTML = `<input name="start_time" type="hidden" value="${start_time/1000.0}"/>
      <input name="end_time" type="hidden" value="${end_time/1000.0}"/>
      <input name="station_id" type="hidden" value="${station_id}"/>
      <input name="token" type="hidden" value="${token}"/>`
    document.body.appendChild(hiddenForm);
    hiddenForm.submit();
    document.body.removeChild(hiddenForm);
  }

  /**
   * Helper function to generate headers (mostly used for auth token) appended to API calls.
   *
   * @param options Any additional options added to the header.
   * @returns The generated headers.
   */
  generateHeaders(options?: Record<string, unknown>): Record<string, unknown> {
    const token: string = AuthService.getInstance().jwt;

    return {
      ...options,
      Authorization: token ? `Bearer ${token}` : '',
    };
  }

  /**
   * If any of the non-login endpoints return a 401, log the user out of the application (sending them back to the login page).
   * Then re-throws the error.
   *
   * @param error The error response from a call to an endpoint.
   * @returns The passed in error.
   */
  handleError(error: AjaxError): Observable<AjaxError> {
    if (error?.status === 401) {
      AuthService.getInstance().logout();
    }
    return throwError(error);
  }
}
