import { rgba } from 'polished';
import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { Color, FontSize, FontWeight, Spacing } from '../../styling';

export const styling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .gic-nav {
    background: linear-gradient(0deg, ${Color.Purple} 0%, ${Color.Blue} 100%);
    grid-area: nav;
    max-width: 300px;
    overflow: auto;
    transition: all 0.5s ease;
    width: 100%;

    &-items {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
    }

    &-item {
      color: ${Color.White};
      font-size: ${FontSize.MD};

      &--stations {
        height: 200px;
      }

      &__link,
      &__button {
        background: none;
        border: none;
        color: ${Color.White};
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 12px;
        text-align: left;
        text-decoration: none;
        text-overflow: ellipsis;
        transition: border-width 0.5s, background 0.25s;
        white-space: nowrap;
        width: 100%;

        &:hover,
        &:active,
        &:focus,
        &--active {
          background: ${rgba(Color.Black, 0.2)};
        }

        &--active {
          border-left: 4px ${Color.White} solid;
          font-weight: ${FontWeight.Regular};
        }

        &--stations {
          height: 50px;
          left: 0;
          position: absolute;
          top: 0;
          transform: rotate(-90deg) translate(-200px);
          transform-origin: 0 0;
          width: 200px;
        }
      }

      &:last-of-type {
        align-items: flex-end;
        display: flex;
        flex: 1;

        & > * {
          text-align: center;
        }
      }

      &__icon + span {
        margin-left: ${Spacing.MD};
      }

      &--menu,
      &--dashboard,
      &--stations {
        border-bottom: 1px solid ${rgba(Color.White, 0.5)};
      }
    }

    &-stations {
      min-width: 200px;
    }

    &-menu {
      &-item {
        color: ${theme.text};

        &__icon + span {
          margin-left: ${Spacing.SM};
        }
      }
    }

    &-stations {
      &__item {
        display: block;
        font-size: ${FontSize.MD};
        padding: ${Spacing.XS} ${Spacing.SM};
        width: 100%;
      }
    }

    &.collapsed {
      min-width: 50px;
      max-width: 50px;
      width: 50px;

      .gic-nav-item {
        &__link,
        &__button {
          text-align: center;
        }
      }
    }

    &.expanded {
      min-width: 200px;
    }
  }

  .gic-menu {
    ul {
      padding: 0;
    }

    .gic-nav-menu-item {
      &:hover,
      &:focus {
        background-color: ${theme.interactive};
      }

      & + * {
        border-top: 1px solid ${Color.Grey};
      }
    }
  }
`;
