import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * LOADING COMPONENT
 */
export class LoadingComponent extends Component<unknown, unknown> {
  render(): JSX.Element {
    return (
      <div className="gic-loading">
        <CircularProgress classes={{ root: 'gic-loading__spinner' }} />
        <p className="gic-loading__text">Loading...</p>
      </div>
    );
  }
}
