import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { NoSsr } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';

import { createMuiTheme, ThemeOptions, ThemeProvider as MaterialThemeProvider } from '@material-ui/core';

import { LoadingComponent } from './components';
import { ContentPage, LoginPage } from './pages';
import { AuthService, SettingsService, ThemeService } from './services';
import { Utils } from './shared';
import { darkTheme, GlobalStyles, lightTheme, muiDarkTheme, muiLightTheme, Theme } from './styling';

export interface AppState {
  unsubscribe$: Subject<void>;
  theme: Theme;
  muiTheme: ThemeOptions;
  isAuthenticated: boolean;
  isLoading: boolean;
  isGettingSettings: boolean;
}

export default class App extends Component<unknown, AppState> {
  constructor(props: unknown) {
    super(props);

    this.state = {
      isLoading: true,
      isGettingSettings: true,
      unsubscribe$: new Subject<void>(),
      theme: ThemeService.getInstance().theme,
      muiTheme: createMuiTheme(ThemeService.getInstance().theme === Theme.LIGHT ? muiLightTheme : muiDarkTheme),
      isAuthenticated: false,
    };
  }

  /**
   * On component mount:
   *  - initialize the state with whether or not the user is authenticated.
   *  - create a subscription to the theme subject to update the current theme.
   *  - create a subscription to the authentication service to keep track of whether or not the user is authenticated.
   */
  componentDidMount(): void {
    this.createIsAuthenticatedSubscription();
    this.createThemeSubscription();
    this.initialCheckIsAuthenticated();
    this.getSettings();
  }

  /**
   * On component unmount:
   *  - complete any active subscriptions
   */
  componentWillUnmount(): void {
    Utils.completeSubject(this.state.unsubscribe$);
  }

  /**
   * If the application was built with REACT_APP_SKIP_AUTHENTICATION set to true,
   * skip authentication by settings isLoading to false and isAuthenticated to true.
   * Otherwise, checks to see if the the user is authenticated using the AuthService and updates the state based on the response.
   */
  initialCheckIsAuthenticated = (): void => {
    if (process.env.REACT_APP_SKIP_AUTHENTICATION) {
      this.setState({ ...this.state, isLoading: false, isAuthenticated: true });
    } else {
      AuthService.getInstance()
        .checkIfAuthenticated()
        .pipe(takeUntil(this.state.unsubscribe$), take(1))
        .subscribe((isAuthenticated: boolean) => {
          this.setState({ ...this.state, isLoading: false, isAuthenticated });
        });
    }
  };

  /**
   * Creates a subscription to the theme subject in the theme service to update the ThemeProvider whenever the theme is updated.
   * Updates the state based on the response.
   */
  createThemeSubscription = (): void => {
    ThemeService.getInstance()
      .theme$.pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((theme: Theme) => {
        this.setState({
          ...this.state,
          theme,
          muiTheme: createMuiTheme(theme === Theme.LIGHT ? muiLightTheme : muiDarkTheme),
        });
      });
  };

  /**
   * Creates a subscription to the auth service to update what page(s) are accessible based on whether or not the user is still
   * authenticated. Updates the state based on the response.
   */
  createIsAuthenticatedSubscription = (): void => {
    AuthService.getInstance()
      .isAuthenticated$.pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((isAuthenticated: boolean) => {
        this.setState({
          ...this.state,
          isAuthenticated,
        });
      });
  };

  /**
   * Gets the settings to set up how the application looks and functions.
   */
  getSettings = (): void => {
    SettingsService.getInstance()
      .getSettings()
      .pipe(takeUntil(this.state.unsubscribe$))
      .subscribe(() => this.setState({ ...this.state, isGettingSettings: false }));
  };

  render(): JSX.Element {
    const routes: JSX.Element = (
      <Router>
        <Switch>
          {this.state.isAuthenticated ? (
            <Route path="/" component={ContentPage}></Route>
          ) : (
            <>
              <Route exact path="/login" component={LoginPage}></Route>
              <Redirect to="/login" />
            </>
          )}

          <Redirect to="/" />
        </Switch>
      </Router>
    );

    return (
      <>
        <Helmet>
          <meta name="theme-color" content={(this.state.theme === Theme.LIGHT ? lightTheme : darkTheme).main.background} />
        </Helmet>
        <NoSsr>
          <MaterialThemeProvider theme={this.state.muiTheme}>
            <ThemeProvider theme={this.state.theme === Theme.LIGHT ? lightTheme : darkTheme}>
              <GlobalStyles />
              {this.state.isLoading || this.state.isGettingSettings ? <LoadingComponent /> : routes}
            </ThemeProvider>
          </MaterialThemeProvider>
        </NoSsr>
      </>
    );
  }
}
