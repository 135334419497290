export enum Color {
  Black = '#000000',
  Blue = '#4864A6',
  Light_Blue = '#70B6FB',
  Dark_Grey = '#888888',
  Darker_Grey = '#454545',
  Darkest_Grey = '#252525',
  Almost_Black = '#1f1f1f',
  Green = '#00D21D',
  Grey = '#CCCCCC',
  Light_Grey = '#F5F5F5',
  Orange = '#FF9F32',
  Purple = '#5548A6',
  Red = '#D42828',
  Near_White = '#EFEFEF',
  White = '#FFFFFF',
}

export enum Spacing {
  XXXS = '2px',
  XXS = '4px',
  XS = '8px',
  SM = '12px',
  MD = '16px',
  LG = '24px',
  XL = '36px',
  XXL = '48px',
}

export enum FontSize {
  XS = '8px',
  SM = '12px',
  MD = '14px',
  LG = '18px',
  XL = '24px',
  XXL = '32px',
  XXXL = '48px',
}

export enum FontWeight {
  Light = 300,
  Regular = 400,
  Semibold = 500,
  Bold = 600,
}

export enum BreakPoint {
  LARGE = '1600px',
  MEDIUM = '1200px',
  SMALL = '1024px',
  X_SMALL = '768px',
}
