import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { LoadingComponent, NotificationComponent, SideNavComponent } from '../../components';
import { ApiService, MagstarShortStationInfo, NotificationService, NotificationConfig, StationsService } from '../../services';
import { Utils } from '../../shared';

import { DashboardPage } from '../dashboard/dashboard.page';
import { StationPage } from '../station/station.page';

export interface ContentState {
  stations: MagstarShortStationInfo[];
  isLoading: boolean;
  unsubscribe$: Subject<void>;
  notifications: NotificationConfig[];
}

/**
 * CONTENT PAGE
 */
export class ContentPage extends Component<unknown, ContentState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      stations: [],
      isLoading: true,
      unsubscribe$: new Subject<void>(),
      notifications: NotificationService.getInstance().notifications,
    };
  }

  componentDidMount(): void {
    this.subscribeToStations();
    this.subscribeToNotifications();
    this.getListOfStations();
  }

  /**
   * On component unmount, complete any active subscriptions.
   */
  componentWillUnmount(): void {
    Utils.completeSubject(this.state.unsubscribe$);
  }

  /**
   * Makes the call to the ApiService to get the list of available stations.
   */
  getListOfStations = (): void => {
    ApiService.getInstance()
      .getStations()
      .pipe(take(1), takeUntil(this.state.unsubscribe$))
      .subscribe((stations: MagstarShortStationInfo[]) => {
        StationsService.getInstance().stations = stations;
      });
  };

  /**
   * Creates a subscription to the list of stations in the Stations Service.
   * The list of stations is used to generate the navigation items and the internal page (routes).
   */
  subscribeToStations = (): void => {
    StationsService.getInstance()
      .stations$.pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((stations: MagstarShortStationInfo[]) => {
        this.setState({ ...this.state, stations, isLoading: false });
      });
  };

  /**
   * Creates a subscription to the list of notification configs in the Notification Service.
   * The list of notifications configs is used to generate the notifications displayed at the top of the application.
   */
  subscribeToNotifications = (): void => {
    NotificationService.getInstance()
      .onChange$.pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((notifications: NotificationConfig[]) => {
        this.setState({ ...this.state, notifications });
      });
  };

  /**
   * Generates the redirect url based on the current pathname.
   * Redirect the user to the dashboard page if
   *  - no pathname
   *  - pathname equals '/login' or '/stations'
   */
  getRedirectUrl = (): string => {
    const pathName: string = window.location.pathname;
    if (!pathName || pathName === '/' || pathName === '/login' || pathName === '/stations') {
      return '/dashboard';
    }
    return pathName;
  };

  /**
   * Generates the notifications DOM element.
   *
   * @returns The generated notifications DOM element.
   */
  generateNotifications = (): JSX.Element => {
    return (
      <div className="header-notifications">
        {this.state.notifications.map((notification: NotificationConfig) => (
          <NotificationComponent key={notification.uuid} type={notification.type}>
            {notification.text || notification.content}
          </NotificationComponent>
        ))}
      </div>
    );
  };

  /**
   * Generates the content DOM element.
   *
   * @returns The generated content DOM element.
   */
  generateContent = (): JSX.Element => {
    return (
      <main className="gic-content">
        <Route path="/dashboard">
          <DashboardPage></DashboardPage>
        </Route>

        {this.state?.stations?.map((station: MagstarShortStationInfo) => (
          <Route
            className={`nav-item-${station.name}`}
            key={station.station_id}
            path={`/station/${station?.name ? station.name.replace(/\s+/g, '-').toLowerCase() : station.station_id}`}>
            <StationPage station={station}></StationPage>
          </Route>
        ))}

        <Redirect to={this.getRedirectUrl()} />
      </main>
    );
  };

  render(): JSX.Element {
    const notifications: JSX.Element = this.generateNotifications();
    const content: JSX.Element = this.generateContent();

    return (
      <div className="gic-page-wrapper">
        {notifications}
        <div className="gic-content-wrapper">
          <SideNavComponent></SideNavComponent>
          {this.state.isLoading ? <LoadingComponent /> : content}
        </div>
      </div>
    );
  }
}
