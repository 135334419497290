import React, { Component, MouseEvent } from 'react';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faClock, faDownload, faPlayCircle, faPauseCircle, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Popover, Tooltip } from '@material-ui/core';

import { DataMode, DateService, StationsService, TimeFormat } from '../../services';
import { Utils } from '../../shared';
import { DownloadFormComponent } from '../download-form/download-form.component';
import { HistoricFormComponent } from '../historic-form/historic-form.component';

export interface ControlBarState {
  unsubscribe$: Subject<void>;
  isPaused: boolean;
  dataMode: DataMode;
  time: {
    start: number;
    end: number;
    format: TimeFormat;
  };
  menuAnchorElements: {
    download: HTMLButtonElement;
    historic: HTMLButtonElement;
  };
}

/**
 * CONTROL BAR COMPONENT
 *
 * The control bar handles displaying and controlling the following:
 *  - the current time/ date (range)
 *  - the current data mode (historic vs streaming)
 * And provided a way to download the available station data.
 */
export class ControlBarComponent extends Component<unknown, ControlBarState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      unsubscribe$: new Subject<void>(),
      isPaused: StationsService.getInstance().isPaused,
      dataMode: StationsService.getInstance().mode,
      time: {
        start: DateService.getInstance().startTime,
        end: DateService.getInstance().endTime,
        format: DateService.getInstance().timeFormat,
      },
      menuAnchorElements: {
        download: null,
        historic: null,
      },
    };
  }

  /**
   * On component mount:
   *  - create a subscription to keep track of whether or not the polling is paused
   *  - create a subscription to keep track of the current data mode (historic vs streaming)
   *  - create a subscription to keep track of the time (current or range) of the displayed data
   */
  componentDidMount(): void {
    this.createSubscriptions();
  }

  /**
   * On component unmount, complete any active subscriptions.
   */
  componentWillUnmount(): void {
    Utils.completeSubject(this.state.unsubscribe$);
  }

  /**
   * Subscribes to the:
   *  - stationService isPaused flag
   *  - stationService mode
   *  - dateService startTime
   *  - dateService endTime
   *  - dateService timeFormat
   * To dynamically update the component state when these values are updated.
   */
  createSubscriptions = (): void => {
    const stationsService: StationsService = StationsService.getInstance();
    const dateService: DateService = DateService.getInstance();

    combineLatest([stationsService.isPaused$, stationsService.mode$, dateService.startTime$, dateService.endTime$, dateService.timeFormat$])
      .pipe(takeUntil(this.state.unsubscribe$), debounceTime(0), distinctUntilChanged())
      .subscribe(([isPaused, dataMode, start, end, format]: [boolean, DataMode, number, number, TimeFormat]) => {
        this.setState({
          ...this.state,
          isPaused,
          dataMode,
          time: {
            ...this.state.time,
            start,
            end,
            format,
          },
        });
      });
  };

  /**
   * When the download CSV station data button is clicked, open the popup menu.
   *
   * @param event The click event.
   */
  handleDownloadMenuClick = (event: MouseEvent<HTMLButtonElement>): void => {
    this.setState({
      ...this.state,
      menuAnchorElements: { ...this.state.menuAnchorElements, download: event.currentTarget },
    });
  };

  /**
   * When anything other than download CSV station data popup menu is clicked, close the menu.
   */
  handleDownloadMenuClose = (): void => {
    this.setState({
      ...this.state,
      menuAnchorElements: { ...this.state.menuAnchorElements, download: null },
    });
  };

  /**
   * When the historic button is clicked, open the popup menu.
   *
   * @param event The click event.
   */
  handleHistoricMenuClick = (event: MouseEvent<HTMLButtonElement>): void => {
    this.setState({
      ...this.state,
      menuAnchorElements: { ...this.state.menuAnchorElements, historic: event.currentTarget },
    });
  };

  /**
   * When anything other than historic popup menu is clicked, close the menu.
   */
  handleHistoricMenuClose = (): void => {
    this.setState({
      ...this.state,
      menuAnchorElements: { ...this.state.menuAnchorElements, historic: null },
    });
  };

  /**
   * Generates the timestamp to be used in render().
   *
   * @returns The generated timestamp DOM element.
   */
  generateTimestamp = (): JSX.Element => {
    const displayEndTime: boolean = this.state.dataMode === DataMode.HISTORIC && !!this.state.time.end;

    return (
      <Tooltip
        title={`Time Format: ${this.state.time.format === TimeFormat.LOCAL ? 'Local' : 'UTC'}`}
        aria-label={this.state.time.format === TimeFormat.LOCAL ? 'Local' : 'UTC'}>
        <div className="control-bar__segment control-bar__timestamp">
          {Utils.formatDate(this.state.time.start, this.state.time.format)}{' '}
          {displayEndTime && `- ${Utils.formatDate(this.state.time.end, this.state.time.format)}`}
        </div>
      </Tooltip>
    );
  };

  /**
   * Generates the play/ pause button to be used in render().
   *
   * @returns The generated play/ pause button DOM element.
   */
  generatePlayPauseButton = (): JSX.Element => {
    return (
      <Tooltip
        title={this.state.isPaused ? 'Resume Polling' : 'Pause Polling'}
        aria-label={this.state.isPaused ? 'Resume Polling' : 'Pause Polling'}>
        <button
          className="control-bar__segment control-bar__button control-bar__button--play-pause"
          onClick={StationsService.getInstance().toggleIsPaused}>
          <FontAwesomeIcon icon={this.state.isPaused ? faPauseCircle : faPlayCircle} className="control-bar__button-icon" />
        </button>
      </Tooltip>
    );
  };

  /**
   * Generates the data mode button to be used in render().
   *
   * @returns The generated data mode button DOM element.
   */
  generateDataModeButton = (): JSX.Element => {
    return (
      <>
        <Tooltip
          title={`Switch to ${this.state.dataMode === DataMode.STREAMING ? 'Historical Mode' : 'Streaming Mode'}`}
          aria-label={`switch to ${this.state.dataMode === DataMode.STREAMING ? 'historical mode' : 'streaming mode'}`}>
          <button
            className="control-bar__segment control-bar__button control-bar__button--data-mode"
            onClick={StationsService.getInstance().toggleDataMode}>
            {this.state.dataMode === DataMode.STREAMING ? (
              <>
                <FontAwesomeIcon icon={faChartLine} className="control-bar__button-icon" />
                <span>Streaming Mode</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faClock} className="control-bar__button-icon" />
                <span>Historical Mode</span>
              </>
            )}
          </button>
        </Tooltip>
        {this.state.dataMode === DataMode.HISTORIC && (
          <>
            <button
              className="control-bar__segment control-bar__button control-bar__button--historic-options"
              aria-controls="historic-menu"
              aria-haspopup="true"
              onClick={this.handleHistoricMenuClick}>
              <FontAwesomeIcon icon={faAngleDown} className="control-bar__button-icon" />
            </button>
            <Popover
              id="historic-menu"
              classes={{ paper: 'gic-menu' }}
              anchorEl={this.state.menuAnchorElements.historic}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(this.state.menuAnchorElements.historic)}
              onClose={this.handleHistoricMenuClose}>
              <HistoricFormComponent></HistoricFormComponent>
            </Popover>
          </>
        )}
      </>
    );
  };

  /**
   * Generates the download button to be used in render().
   *
   * @returns The generated download button DOM element.
   */
  generateDownloadButton = (): JSX.Element => {
    return (
      <>
        <Tooltip title="Download" aria-label="download">
          <button
            className="control-bar__segment control-bar__button control-bar__button--download"
            aria-controls="download-menu"
            aria-haspopup="true"
            onClick={this.handleDownloadMenuClick}>
            <FontAwesomeIcon icon={faDownload} className="control-bar__button-icon" />
          </button>
        </Tooltip>
        <Popover
          id="download-menu"
          classes={{ paper: 'gic-menu' }}
          anchorEl={this.state.menuAnchorElements.download}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(this.state.menuAnchorElements.download)}
          onClose={this.handleDownloadMenuClose}>
          <DownloadFormComponent />
        </Popover>
      </>
    );
  };

  render(): JSX.Element {
    const timestamp: JSX.Element = this.generateTimestamp();
    const playPauseButton: JSX.Element = this.generatePlayPauseButton();
    const dataModeButton: JSX.Element = this.generateDataModeButton();
    const downloadButton: JSX.Element = this.generateDownloadButton();

    return (
      <div className="control-bar">
        {timestamp}
        {this.state.dataMode === DataMode.STREAMING && playPauseButton}
        {dataModeButton}
        {downloadButton}
      </div>
    );
  }
}
