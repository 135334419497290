import React, { ChangeEvent, Component, MouseEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { Subject, of } from 'rxjs';
import { map, catchError, takeUntil } from 'rxjs/operators';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AuthService } from '../../services';
import { Settings, SettingsService } from '../../services/settings';
import { Utils } from '../../shared';

export interface LoginState {
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  username: string;
  password: string;
  settings: Settings;
  tosCheckbox: boolean;
  isError: boolean;
  unsubscribe$: Subject<void>;
  usernameRef: React.RefObject<HTMLInputElement>;
}

export class LoginPage extends Component<unknown, LoginState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      isLoggingIn: false,
      isLoggedIn: false,
      username: '',
      password: '',
      settings: {} as Settings,
      tosCheckbox: false,
      isError: false,
      unsubscribe$: new Subject<void>(),
      usernameRef: React.createRef(),
    };
  }

  /**
   * On component mount, set the document title and focus on the username input.
   */
  componentDidMount(): void {
    document.title = 'Login | CPI Magnetometer Resource Tool';
    this.state.usernameRef.current.focus();

    this.createSettingsSubscription();
  }

  /**
   * On component unmount, complete any active subscriptions.
   */
  componentWillUnmount(): void {
    Utils.completeSubject(this.state.unsubscribe$);
  }

  /**
   * Creates a subscription to the settings service to update the following settings:
   *  - whether tos checkbox is displayed
   *  - the tos url
   *  - the logo url
   *  - the logo alt text
   */
  createSettingsSubscription = (): void => {
    SettingsService.getInstance()
      .settings$.pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((settings: Settings) => {
        this.setState({
          ...this.state,
          settings,
        });
      });
  };

  /**
   * On form input change (username or password), update the corresponding state attributes.
   *
   * @param event The HTML input element change event containing the input value.
   */
  handleFormChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ ...this.state, [event.target.name]: event.target.value, isError: false });
  };

  /**
   * Toggles the tosCheckbox form checkbox value.
   */
  handleTosCheckboxInputChange = (): void => {
    this.setState({ ...this.state, tosCheckbox: !this.state.tosCheckbox });
  };

  /**
   * Attempts to log in using the auth service with the provided username and password values from the inputs.
   *
   * On success, the auth service to handle redirecting the user to the dashboard.
   * On failure, display an error message.
   *
   * @param event The button click mouse event (only used to prevent the default submit button behavior).
   */
  login = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    this.setState({ ...this.state, isLoggingIn: true, isError: false });
    AuthService.getInstance()
      .login(this.state.username, this.state.password)
      .pipe(takeUntil(this.state.unsubscribe$))
      .subscribe(
        () => this.setState({ ...this.state, isLoggingIn: false, isLoggedIn: true }),
        () => this.setState({ ...this.state, isLoggingIn: false, isLoggedIn: false, isError: true }),
      );
  };

  /**
   * Generates the username label and input DOM element.
   *
   * @returns The generated username label and input DOM element.
   */
  generateUsername = (): JSX.Element => {
    return (
      <div className="gic-input gic-input__text">
        <label htmlFor="username" className="display-none">
          Username
        </label>
        <input
          id="username"
          type="text"
          name="username"
          placeholder="username"
          value={this.state.username}
          onChange={this.handleFormChange}
          ref={this.state.usernameRef}
        />
      </div>
    );
  };

  /**
   * Generates the password label and input DOM element.
   *
   * @returns The generated password label and input DOM element.
   */
  generatePassword = (): JSX.Element => {
    return (
      <div className="gic-input gic-input__text">
        <label htmlFor="password" className="display-none">
          Password
        </label>
        <input
          id="password"
          type="password"
          name="password"
          placeholder="password"
          value={this.state.password}
          onChange={this.handleFormChange}
        />
      </div>
    );
  };

  /**
   * Generates the terms of service checkbox input.
   *
   * @returns The generated terms of service checkbox DOM input.
   */
  generateTosCheckbox = (): JSX.Element => {
    return (
      <div className="gic-input gic-input__checkbox">
        <input id="tos-checkbox" type="checkbox" checked={this.state.tosCheckbox} onChange={this.handleTosCheckboxInputChange} />
        <label htmlFor="tos-checkbox">
          Agree to <a href={this.state['tosUrl']}>Terms of Service</a>
        </label>
      </div>
    );
  };

  render(): JSX.Element {
    const username: JSX.Element = this.generateUsername();
    const password: JSX.Element = this.generatePassword();
    const tosCheckbox: JSX.Element = this.generateTosCheckbox();

    if (this.state.isLoggedIn) {
      return <Redirect to="./dashboard" />;
    }
    return (
      <main className="gic-login">
        <div className="gic-login__wrapper">
          <form className="gic-login__form">
            <img src={this.state.settings.logoUrl} alt={this.state.settings.logoAltText} className="gic-login__logo" />
            {this.state.isError && (
              <div className="error gic-login__error">
                <FontAwesomeIcon icon={faExclamationCircle} className="gic-login__error-icon" />
                <span className="gic-login__error-message">Invalid username and/or password.</span>
              </div>
            )}
            {username}
            {password}
            {this.state.settings.includeTosCheckbox && tosCheckbox}
            <button
              type="submit"
              disabled={
                !this.state.password ||
                !this.state.username ||
                this.state.isLoggingIn ||
                (!this.state.tosCheckbox && this.state.settings.includeTosCheckbox)
              }
              onClick={this.login}
              className="gic-login__button">
              {this.state.isLoggingIn ? <CircularProgress size="15px" classes={{ root: 'gic-login__button-spinner' }} /> : 'Login'}
            </button>
          </form>
          <div className="gic-login__about">Magnetometer data visualization and resource tool.</div>
          <div className="gic-login__version">Version 1.1.0</div>
        </div>
      </main>
    );
  }
}
