import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { Spacing } from '../../styling';

export const styling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .historic-form {
    min-width: 400px;
    padding: ${Spacing.LG};

    .apply-button {
      margin: ${Spacing.MD} 0 0 auto;

      &-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }

    .date-time-group {
      display: flex;
      margin: ${Spacing.SM};
    }
  }
`;
