import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { Color, FontSize, FontWeight, Spacing } from './styles.enum';

export const componentsStyling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .gic {
    &-button {
      background: ${Color.Purple};
      border: none;
      color: ${Color.White};
      font-size: ${FontSize.MD};
      font-weight: ${FontWeight.Bold};
      padding: ${Spacing.MD} ${Spacing.XL};
      text-transform: uppercase;

      &:hover,
      &:active,
      &:focus {
        &:not(:disabled) {
          background-color: ${Color.Blue};
        }
      }

      &:disabled {
        opacity: 0.5;
      }

      &__loading-spinner {
        color: ${Color.White};
      }

      &--link {
        background: none;
        color: ${Color.Purple};
        padding: 0;
        text-transform: none;

        &:hover,
        &:active,
        &:focus {
          background: none;
          text-decoration: underline;
        }
      }
    }

    &-form {
      &__field-group {
        & + .gic-form__field-group {
          margin-top: ${Spacing.LG};
        }
      }
    }

    &-input {
      label,
      input {
        color: ${theme.text};
      }

      &:not(.gic-input--inline) + .gic-input:not(.gic-input--inline) {
        margin-top: ${Spacing.XS};
      }

      &--inline + .gic-input--inline {
        margin-left: ${Spacing.XS};
      }

      &__checkbox,
      &__radio {
        & > * {
          &:not(:disabled),
          &:not(.--disabled),
          &:not(.--not-allowed-disabled) {
            cursor: pointer;
          }

          &:disabled,
          &.--disabled,
          &.--not-allowed-disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        & > label {
          margin-left: ${Spacing.XXS};
        }
      }
    }

    &-link {
      color: ${theme.interactive.text};
      text-decoration: none;

      &:visited {
        color: ${theme.interactive.text};
      }

      &:hover,
      &:active,
      &:focus {
        background: none;
        text-decoration: underline;
      }
    }

    &-button--link {
      background: none;
      background-color: none;
      border: none;
      color: ${theme.interactive.text};
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        &:not(:disabled) {
          background: none;
          background-color: none;
          text-decoration: underline;
        }
      }
    }

    &-menu {
      background: ${theme.component.background};
      border: none;
      border-radius: 0;
      color: ${theme.text};
    }

    &-table {
      &__container {
        border: none;
        border-radius: 0;
        box-shadow: none;
      }

      th,
      td {
        border: none;
        color: ${theme.text};
      }

      thead {
        & > tr {
          background-color: ${theme.component.background};
        }

        th {
          font-weight: ${FontWeight.Bold};
        }
      }

      tbody {
        & > tr:nth-child(even) {
          background-color: ${theme.component.background};
        }
        & > tr:nth-child(odd) {
          background-color: ${theme.component.altBackground};
        }
      }
    }
  }

  .MuiPickersToolbar-toolbar,
  .MuiPickersClock-pin,
  .MuiPickersClockPointer-pointer {
    background-color: ${Color.Purple};
  }

  .MuiPickerDTTabs-tabs {
    background-color: ${Color.Purple};
    border-top: 1px solid ${Color.White};
  }

  .PrivateTabIndicator-colorSecondary-3 {
    background-color: ${Color.Light_Blue};
  }

  .MuiPickersDay-daySelected {
    background-color: ${Color.Purple};

    &:hover {
      background-color: ${Color.Blue};
    }
  }

  .MuiPickersDay-current {
    color: ${theme.interactive.text};
  }

  .MuiPickersClockPointer-thumb {
    border-color: ${Color.Purple};
  }
`;
