import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { combineLatest, forkJoin, Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { cloneDeep } from 'lodash';

import { ControlBarComponent, Status, StatusBoxComponent } from '../../components';
import {
  ApiService,
  DataMode,
  DateService,
  GetStationMeasurementsByIdParams,
  MagstarMeasurementResult,
  MagstarShortStationInfo,
  MagstarStationInfo,
  StationsService,
} from '../../services';
import { Utils } from '../../shared';

export interface StationsTableData {
  id: number;
  name: string;
  location: string;
  horizontal_field_magnitude: number;
  horizontal_field_angle: number;
  first_horizontal_field_magnitude?: number;
  first_horizontal_field_angle?: number;
}

export interface DashboardPageState {
  stationsTableData: StationsTableData[];
  unsubscribe$: Subject<void>;
}

/**
 * DASHBOARD PAGE
 */
export class DashboardPage extends Component<unknown, DashboardPageState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      stationsTableData: [],
      unsubscribe$: new Subject<void>(),
    };
  }

  /**
   * On component mount:
   *  - start polling the GET stations/ endpoint to populate the table.
   */
  componentDidMount(): void {
    this.pollStations();
    this.getHistoricStationInfo();
    document.title = 'Dashboard | CPI Magnetometer Resource Tool';
  }

  /**
   * On component unmount, complete any active subscriptions.
   */
  componentWillUnmount(): void {
    Utils.completeSubject(this.state.unsubscribe$);
  }

  /**
   * Gets the list of stations used to populate the table.
   *
   * Poll the GET stations/ endpoint every five (5) seconds unless the isPaused flag is true or if the mode is NOT streaming.
   */
  pollStations = (): void => {
    timer(0, 5000)
      .pipe(
        takeUntil(this.state.unsubscribe$),
        filter(() => !StationsService.getInstance().isPaused && StationsService.getInstance().mode === DataMode.STREAMING),
        switchMap(() =>
          forkJoin(
            StationsService.getInstance().stations.map((station: MagstarShortStationInfo) =>
              ApiService.getInstance().getStationById(station.station_id),
            ),
          ),
        ),
      )
      .subscribe((stationInfo: MagstarStationInfo[]) => {
        const stationsTableData: StationsTableData[] = stationInfo.map((info: MagstarStationInfo) => ({
          id: info.station_id,
          name: info.name,
          location: info.location,
          horizontal_field_magnitude: info.latest_horizontal_field_magnitude,
          horizontal_field_angle: info.latest_horizontal_field_angle,
        }));

        this.setState({ ...this.state, stationsTableData });
        DateService.getInstance().updateTime({ startTime: Date.now() });
      });
  };

  /**
   * Gets the (historic) information for all stations.
   * Gets the first values from the startTime and the
   */
  getHistoricStationInfo = (): void => {
    const apiService: ApiService = ApiService.getInstance();

    combineLatest([
      StationsService.getInstance().mode$,
      DateService.getInstance().startTime$.pipe(startWith(DateService.getInstance().startTime)),
      DateService.getInstance().endTime$.pipe(startWith(DateService.getInstance().endTime)),
    ])
      .pipe(
        takeUntil(this.state.unsubscribe$),
        debounceTime(0),
        distinctUntilChanged(),
        filter(([mode]: [DataMode, number, number]) => mode === DataMode.HISTORIC),
        switchMap(([, startTime, endTime]: [DataMode, number, number]) => {
          const stations: MagstarShortStationInfo[] = StationsService.getInstance().stations;
          const afterParams: GetStationMeasurementsByIdParams = { after_ts: startTime, limit: 1 };
          const beforeParams: GetStationMeasurementsByIdParams = { before_ts: endTime, limit: 1, reverse_order: true };

          return forkJoin(
            stations.map((station: MagstarShortStationInfo) =>
              forkJoin({
                getStationsById: apiService.getStationById(station.station_id),
                after: apiService.getStationMeasurementsById(station.station_id, cloneDeep(afterParams)),
                before: apiService.getStationMeasurementsById(station.station_id, cloneDeep(beforeParams)),
              }),
            ),
          );
        }),
      )
      .subscribe(
        (stationInfo: { getStationsById: MagstarStationInfo; after: MagstarMeasurementResult; before: MagstarMeasurementResult }[]) => {
          const stationsTableData: StationsTableData[] = stationInfo.map(
            (info: { getStationsById: MagstarStationInfo; after: MagstarMeasurementResult; before: MagstarMeasurementResult }) => ({
              id: info.getStationsById.station_id,
              name: info.getStationsById.name,
              location: info.getStationsById.location,
              horizontal_field_magnitude: info.after.measurements[0]?.horizontal_field_magnitude,
              horizontal_field_angle: info.after.measurements[0]?.horizontal_field_angle,
              first_horizontal_field_angle: info.before.measurements[0]?.horizontal_field_angle,
              first_horizontal_field_magnitude: info.before.measurements[0]?.horizontal_field_magnitude,
            }),
          );

          this.setState({ ...this.state, stationsTableData });
        },
      );
  };

  /**
   * Generates the header DOM element.
   *
   * @returns The generated header DOM element.
   */
  generateHeader = (): JSX.Element => {
    return (
      <div className="section header dashboard__header">
        <h1>Dashboard</h1>
        <ControlBarComponent />
      </div>
    );
  };

  /**
   * Generates the statuses DOM element.
   *
   * @returns The generated status DOM element.
   */
  generateStatuses = (): JSX.Element => {
    return (
      <div className="section gic-statuses gic-dashboard__statuses">
        <div className="gic-status-box__container">
          <StatusBoxComponent status={Status.ERROR} text={{ main: 'main', sub: 'sub' }} />
        </div>
        <div className="gic-status-box__container">
          <StatusBoxComponent status={Status.ERROR} text={{ main: 'main', sub: 'sub' }} />
          <StatusBoxComponent status={Status.ERROR} text={{ main: 'main', sub: 'sub' }} />
          <StatusBoxComponent status={Status.ERROR} text={{ main: 'main', sub: 'sub' }} />
        </div>
      </div>
    );
  };

  /**
   * Generates the stations (table) DOM element.
   *
   * @returns The generated stations (table) DOM element.
   */
  generateStations = (): JSX.Element => {
    return (
      <div className="section dashboard__stations">
        <h2>Stations</h2>
        <TableContainer component={Paper} classes={{ root: 'gic-table__container' }}>
          <Table classes={{ root: 'gic-table stations-table' }} aria-label="Stations table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Location</TableCell>
                {this.state.stationsTableData[0]?.first_horizontal_field_magnitude && (
                  <TableCell align="right">First Horizontal Field (nT)</TableCell>
                )}
                <TableCell align="right">Horizontal Field (nT)</TableCell>
                {this.state.stationsTableData[0]?.first_horizontal_field_angle && (
                  <TableCell align="right">First Angle (deg. East)</TableCell>
                )}
                <TableCell align="right">Angle (deg. East)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.stationsTableData.map((row: StationsTableData) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <NavLink to={Utils.generateStationUrl({ name: row.name, id: row.id })} className="gic-link">
                      {row.name || row.id}
                    </NavLink>
                  </TableCell>
                  <TableCell align="right">{row.location}</TableCell>
                  {this.state.stationsTableData[0]?.first_horizontal_field_magnitude && (
                    <TableCell align="right">{Utils.roundToSingleDecimal(row.first_horizontal_field_magnitude)}</TableCell>
                  )}
                  <TableCell align="right">{Utils.roundToSingleDecimal(row.horizontal_field_magnitude)}</TableCell>
                  {this.state.stationsTableData[0]?.first_horizontal_field_angle && (
                    <TableCell align="right">{Utils.roundToSingleDecimal(row.first_horizontal_field_angle)}</TableCell>
                  )}
                  <TableCell align="right">{Utils.roundToSingleDecimal(row.horizontal_field_angle)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  /**
   * Generates the map DOM element.
   *
   * @returns The generated map DOM element.
   */
  generateMap = (): JSX.Element => {
    return (
      <div className="section dashboard__map">
        <h2>Map</h2>
      </div>
    );
  };

  render(): JSX.Element {
    const header: JSX.Element = this.generateHeader();
    // const statuses: JSX.Element = this.generateStatuses();
    const stations: JSX.Element = this.generateStations();
    // const map: JSX.Element = this.generateMap();

    return (
      <>
        {header}
        {/* {statuses} */}
        {stations}
        {/* {map} */}
      </>
    );
  }
}
