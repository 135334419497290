import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { Color, FontSize, Spacing } from '../../styling';

export const styling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .control-bar {
    background: ${theme.component.background};
    display: flex;
    flex-wrap: nowrap;
    font-size: ${FontSize.MD};
    height: 100%;

    &__button,
    &__segment {
      background: none;
      border: none;
      color: ${theme.interactive.text};
      padding: ${Spacing.SM} ${Spacing.MD};

      & + * {
        border-left: 1px solid ${theme.border};
      }

      &--historic-options {
        border-left: none;
        padding: ${Spacing.SM} ${Spacing.SM};
      }

      &-icon + span {
        margin-left: ${Spacing.XS};
      }
    }

    &__button {
      &:hover {
        background-color: ${theme.interactive.background};
      }

      &--active,
      &:active {
        background-color: ${Color.Purple};
        color: ${Color.White};
      }
    }
  }
`;
