import React, { Component } from 'react';
import { combineLatest, forkJoin, Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faThermometerHalf } from '@fortawesome/free-solid-svg-icons';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from '@material-ui/core';

import { ControlBarComponent, LineChartComponent, Status, StatusBoxComponent } from '../../components';
import {
  ApiService,
  DataMode,
  DateService,
  GetStationMeasurementsByIdParams,
  MagstarMeasurement,
  MagstarMeasurementResult,
  MagstarShortStationInfo,
  MagstarStationInfo,
  StationsService,
  TimeFormat,
} from '../../services';
import { Utils } from '../../shared';

export interface StationInfo {
  id: number;
  name: string;
  location: string;
  lat: number;
  lon: number;
  lastSeen: number;
  earliestTimestamp: number;
  latestTimestamp: number;
  measurements: MagstarMeasurement[];
}

export interface StationProps {
  station: MagstarShortStationInfo;
}

export interface StationState {
  station: StationInfo;
  unsubscribe$: Subject<void>;
  timeFormat: TimeFormat;
  table: {
    rowsPerPage: number;
    currentPage: number;
  };
  isLoadingData: boolean;
}

/**
 * STATION PAGE
 */
export class StationPage extends Component<StationProps, StationState> {
  constructor(props: StationProps) {
    super(props);
    this.state = {
      station: undefined,
      unsubscribe$: new Subject<void>(),
      timeFormat: DateService.getInstance().timeFormat,
      table: {
        rowsPerPage: 10,
        currentPage: 0,
      },
      isLoadingData: false,
    };
  }

  /**
   * On component mount, start polling the info and measurements for the provided station and set the document title.
   */
  componentDidMount(): void {
    this.pollGetStationInfo();
    this.getHistoricStationInfo();
    this.createTimeFormatSub();
    document.title = `${this.props?.station?.name || ''} | CPI Magnetometer Resource Tool`;
  }

  /**
   * On component unmount, complete any active subscriptions.
   */
  componentWillUnmount(): void {
    Utils.completeSubject(this.state.unsubscribe$);
  }

  /**
   * Creates the subscription to the DateService timeFormat.
   * Used to format the timestamps displayed on the page.
   */
  createTimeFormatSub = (): void => {
    DateService.getInstance()
      .timeFormat$.pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((timeFormat: TimeFormat) => this.setState({ ...this.state, timeFormat }));
  };

  /**
   * Gets the (historic) information for a station between the set start and end times.
   */
  getHistoricStationInfo = (): void => {
    combineLatest([
      StationsService.getInstance().mode$,
      DateService.getInstance().startTime$.pipe(startWith(DateService.getInstance().startTime)),
      DateService.getInstance().endTime$.pipe(startWith(DateService.getInstance().endTime)),
    ])
      .pipe(
        takeUntil(this.state.unsubscribe$),
        debounceTime(0),
        distinctUntilChanged(),
        filter(([mode]: [DataMode, number, number]) => mode === DataMode.HISTORIC),
        switchMap(([, startTime, endTime]: [DataMode, number, number]) => {
          this.setState({ ...this.state, isLoadingData: true });

          const id: number = this.props.station.station_id;
          const params: GetStationMeasurementsByIdParams = {
            after_ts: startTime,
            before_ts: endTime,
            limit: 2500, // The max limit
          };
          return forkJoin({
            getStationById: ApiService.getInstance().getStationById(id),
            getStationMeasurementsById: StationsService.getInstance().getStationMeasurementsById(id, params),
          });
        }),
      )
      .subscribe((info: { getStationById: MagstarStationInfo; getStationMeasurementsById: MagstarMeasurement[] }) => {
        const station: StationInfo = {
          id: info.getStationById.station_id,
          name: info.getStationById.name,
          location: info.getStationById.location,
          lat: info.getStationById.lat,
          lon: info.getStationById.lon,
          lastSeen: info.getStationById.last_seen,
          earliestTimestamp: info.getStationById.earliest_timestamp,
          latestTimestamp: info.getStationById.latest_timestamp,
          measurements: info.getStationMeasurementsById,
        };

        this.setState({ ...this.state, station, isLoadingData: false });
      });
  };

  /**
   * Gets the info and last sixty (60) measurements for a given station.
   *
   * Polls the GET stations/{id} and stations/{id}/measurements endpoints every second
   * unless the isPaused flag is true or if the mode is NOT streaming.
   */
  pollGetStationInfo = (): void => {
    const apiService: ApiService = ApiService.getInstance();
    const params: GetStationMeasurementsByIdParams = { reverse_order: true, limit: 60 };

    timer(0, 1000)
      .pipe(
        takeUntil(this.state.unsubscribe$),
        filter(() => !StationsService.getInstance().isPaused && StationsService.getInstance().mode === DataMode.STREAMING),
        switchMap(() =>
          forkJoin({
            getStationsById: apiService.getStationById(this.props.station.station_id),
            getStationMeasurementsById: apiService.getStationMeasurementsById(this.props.station.station_id, params),
          }),
        ),
      )
      .subscribe((info: { getStationsById: MagstarStationInfo; getStationMeasurementsById: MagstarMeasurementResult }) => {
        const measurements: MagstarMeasurement[] = info.getStationMeasurementsById.measurements.sort(
          (a: MagstarMeasurement, b: MagstarMeasurement) => (a.timestamp as number) - (b.timestamp as number),
        );

        const station: StationInfo = {
          id: info.getStationsById.station_id,
          name: info.getStationsById.name,
          location: info.getStationsById.location,
          lat: info.getStationsById.lat,
          lon: info.getStationsById.lon,
          lastSeen: info.getStationsById.last_seen,
          earliestTimestamp: info.getStationsById.earliest_timestamp,
          latestTimestamp: info.getStationsById.latest_timestamp,
          measurements,
        };

        this.setState({ ...this.state, station });

        DateService.getInstance().updateTime({ startTime: Date.now() });
      });
  };

  /**
   * Generates the header DOM element.
   *
   * @returns The generated header DOM element.
   */
  generateHeader = (): JSX.Element => {
    return (
      <div className="section header gic-station__header">
        <div>
          <h1>{this.state?.station?.name || this.state?.station?.id}</h1>
          <div className="gic-station__header-info">
            {this.state?.station?.location && (
              <div className="gic-station__header-info-item">
                <FontAwesomeIcon icon={faLocationArrow} className="gic-station__header-info-item-icon" />
                <span className="gic-station__header-info-item-text">{this.state.station.location}</span>
              </div>
            )}
            {this.state?.station?.measurements[this.state.station.measurements.length - 1]?.temperature && (
              <div className="gic-station__header-info-item">
                <FontAwesomeIcon icon={faThermometerHalf} className="gic-station__header-info-item-icon" />
                <span className="gic-station__header-info-item-text">
                  {Utils.roundToSingleDecimal(this.state?.station?.measurements[this.state.station.measurements.length - 1].temperature)}
                  &#176;C
                </span>
              </div>
            )}
          </div>
        </div>
        <ControlBarComponent />
      </div>
    );
  };

  /**
   * Generates the statuses DOM element.
   *
   * @returns the generated statuses DOM element.
   */
  generateStatuses = (): JSX.Element => {
    return (
      <div className="section gic-statuses gic-dashboard__statuses">
        <div className="gic-status-box__container">
          <StatusBoxComponent status={Status.ERROR} text={{ main: 'main', sub: 'sub' }} />
        </div>
        <div className="gic-status-box__container">
          <StatusBoxComponent status={Status.ERROR} text={{ main: 'main', sub: 'sub' }} />
        </div>
        <div className="gic-status-box__container">
          <StatusBoxComponent status={Status.ERROR} text={{ main: 'main', sub: 'sub' }} />
        </div>
      </div>
    );
  };

  /**
   * Generates the line graph DOM element.
   *
   * @returns The generated line graph DOM element.
   */
  generateLineGraph = (): JSX.Element => {
    return (
      <div className="section line-graph gic-station__line-graph">
        {this.state.isLoadingData ? (
          <div className="gic-station__line-graph-loading-wrapper">
            <CircularProgress />
          </div>
        ) : (
          <LineChartComponent data={this.state.station?.measurements} height={500}></LineChartComponent>
        )}
      </div>
    );
  };

  /**
   * Generates the data (table) DOM element.
   *
   * @returns The generated data (table) DOM element.
   */
  generateDataTable = (): JSX.Element => {
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
      this.setState({ ...this.state, table: { ...this.state.table, rowsPerPage: parseInt(event.target.value, 10), currentPage: 0 } });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      this.setState({ ...this.state, table: { ...this.state.table, currentPage: newPage } });
    };

    const calculateVisibleMeasurements = (): MagstarMeasurement[] => {
      if (this.state.station?.measurements) {
        return this.state.station.measurements
          .slice(
            this.state.table.currentPage * this.state.table.rowsPerPage,
            this.state.table.currentPage * this.state.table.rowsPerPage + this.state.table.rowsPerPage,
          )
          .reverse();
      }

      return [];
    };

    return (
      <div className="section station__measurements">
        <h2>Measurements</h2>
        <TableContainer component={Paper} classes={{ root: 'gic-table__container' }}>
          <Table classes={{ root: 'gic-table measurements-table' }} aria-label="Measurements table">
            <TableHead>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell align="right">
                  B<sub>X</sub> (nT)
                </TableCell>
                <TableCell align="right">
                  B<sub>Y</sub> (nT)
                </TableCell>
                <TableCell align="right">
                  B<sub>Z</sub> (nT)
                </TableCell>
                <TableCell align="right">Horizontal Field (nT)</TableCell>
                <TableCell align="right">Angle (deg. East)</TableCell>
                <TableCell align="right">Temperature (&#176;C)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calculateVisibleMeasurements().map((row: MagstarMeasurement) => (
                <TableRow key={row.timestamp as number}>
                  <TableCell>{Utils.formatDate(row.timestamp as number, this.state.timeFormat)}</TableCell>
                  <TableCell align="right">{Utils.roundToSingleDecimal(row.x)}</TableCell>
                  <TableCell align="right">{Utils.roundToSingleDecimal(row.y)}</TableCell>
                  <TableCell align="right">{Utils.roundToSingleDecimal(row.z)}</TableCell>
                  <TableCell align="right">{Utils.roundToSingleDecimal(row.horizontal_field_magnitude)}</TableCell>
                  <TableCell align="right">{Utils.roundToSingleDecimal(row.horizontal_field_angle)}</TableCell>
                  <TableCell align="right">{Utils.roundToSingleDecimal(row.temperature)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.state.station?.measurements?.length || 0}
          rowsPerPage={this.state.table.rowsPerPage}
          page={this.state.table.currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    );
  };

  render(): JSX.Element {
    const header: JSX.Element = this.generateHeader();
    // const statuses: JSX.Element = this.generateStatuses();
    const lineGraph: JSX.Element = this.generateLineGraph();
    const table: JSX.Element = this.generateDataTable();

    return (
      <>
        {header}
        {/* {statuses} */}
        {lineGraph}
        {table}
      </>
    );
  }
}
