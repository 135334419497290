import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { Color, FontSize, Spacing } from '../../styling';

export const styling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .error {
    color: ${Color.Red};
    margin-bottom: ${Spacing.MD};
  }

  input {
    border: 1px solid ${Color.Grey};
    padding: ${Spacing.SM};
  }

  .gic {
    &-login {
      align-items: center;
      background: linear-gradient(0deg, #5548a6 0%, #4864a6 100%);
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 100vh;
      justify-content: center;
      width: 100vw;

      &__about {
        color: ${Color.White};
        font-size: ${FontSize.MD};
        margin-top: ${Spacing.MD};
      }

      &__version {
        color: ${Color.Grey};
        font-size: ${FontSize.SM};
        margin-top: ${Spacing.MD};
      }

      &__logo {
        margin-bottom: ${Spacing.XL};
        width: 100%;
      }

      &__wrapper {
        text-align: center;

        form {
          background-color: ${Color.White};
          margin: 0 auto;
          max-width: 400px;
          min-width: 300px;
          padding: ${Spacing.XL};
          width: 90%;

          .gic-input {
            input {
              color: ${Color.Almost_Black};
            }

            &__text {
              input {
                display: block;
                width: 100%;

                &#password {
                  margin-top: ${Spacing.LG};
                }
              }

              label {
                display: none;
              }
            }

            &__checkbox {
              label {
                color: ${Color.Almost_Black};
              }
            }
          }

          button {
            background: none;
            border: 4px solid ${Color.Blue};
            color: ${Color.Blue};
            display: block;
            font-weight: 600;
            margin: ${Spacing.LG} auto 0;
            padding: ${Spacing.SM} ${Spacing.XXL};
            text-transform: uppercase;

            &:hover:not(:disabled) {
              background: ${Color.Blue};
              color: ${Color.White};
            }

            &:disabled {
              border-color: ${Color.Grey};
              color: ${Color.Grey};
            }
          }
        }
      }

      &__error {
        &-icon {
          margin-right: ${Spacing.XS};
        }
      }

      &__button {
        min-width: 146px;
      }
    }
  }
`;
