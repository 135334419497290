import React, { ChangeEvent, Component } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { v4 as uuidv4 } from 'uuid';

import { DateService, TimeFormat } from '../../services';
import { cloneDeep } from 'lodash';
import { Utils } from '../../shared';

export enum StartEndDateRadioOption {
  PAST_24_HOURS = 24,
  PAST_7_DAYS = 168,
  PAST_30_DAYS = 720,
  CUSTOM_DAY = -1,
  CUSTOM_RANGE = -2,
}

export interface StartEndDateOnFormChange {
  radioValue: StartEndDateRadioOption;
  startDate: number;
  endDate: number;
}

export interface StartEndDateFormState {
  form: {
    startDate: number;
    endDate: number;
    radioValue: StartEndDateRadioOption;
  };
  uuid: string;
}

export interface StartEndDateFormProps {
  onFormChange: (val: StartEndDateOnFormChange) => void;
}

/**
 * START END DATE FORM COMPONENT
 *
 * The download form is accessible from the control bar. It provides a way to download the data for one (1) or more stations
 * for a specified range of time.
 */
export class StartEndDateFormComponent extends Component<StartEndDateFormProps, StartEndDateFormState> {
  constructor(props: StartEndDateFormProps) {
    super(props);
    this.state = {
      form: {
        startDate: DateService.getInstance().startTime,
        endDate: DateService.getInstance().endTime,
        radioValue: DateService.getInstance().selectedRadioButton,
      },
      uuid: uuidv4(),
    };
  }

  /**
   * On component mount:
   *  - grab current start and end dates
   */
  componentDidMount(): void {
    this.setAndEmitStartingValues();
  }

  /**
   * Emits the selected radio button from the DateService.
   */
  setAndEmitStartingValues = (): void => {
    this.handleRadioChange(DateService.getInstance().selectedRadioButton);
  };

  /**
   * When a time/ date range radio input option is selected, update the state.
   *
   * @param event The radio input change event, used to determine which time/ date range is selected.
   */
  handleRadioInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const radioValue: StartEndDateRadioOption = parseInt(event.target.value);
    this.handleRadioChange(radioValue);
  };

  /**
   * Uses the provided radio value to:
   *  - update the start date, end date, and radio value in the state
   *  - emit the updated state values
   *
   * @param radioValue The new radio value.
   */
  handleRadioChange = (radioValue: StartEndDateRadioOption): void => {
    const response: { startTime: number; endTime: number } = Utils.calculateStartEndTime(
      radioValue,
      {
        startTime: this.state.form.startDate,
        endTime: this.state.form.endDate,
      },
      DateService.getInstance().timeFormat === TimeFormat.UTC,
    );

    this.setState({ ...this.state, form: { radioValue, startDate: response.startTime, endDate: response.endTime } }, () =>
      this.props.onFormChange(this.state.form),
    );
  };

  /**
   * On custom day change, update the state date, end date, and emit the new values.
   *
   * @param date The selected custom day.
   */
  handleCustomDateChange = (date: Date | number): void => {
    const start = cloneDeep(date) as Date;
    start.setHours(0, 0, 0, 0);
    const startDate = Number(start);

    const end: Date = cloneDeep(date) as Date;
    end.setHours(23, 59, 59, 999);
    const endDate = Number(end);

    this.setState({ ...this.state, form: { ...this.state.form, startDate, endDate } }, () => this.props.onFormChange(this.state.form));
  };

  /**
   * On start date change, update the state and emit the new value.
   *
   * @param date The selected start date.
   */
  handleStartDateChange = (date: Date | number): void => {
    this.setState({ ...this.state, form: { ...this.state.form, startDate: Number(date) } }, () => this.props.onFormChange(this.state.form));
  };

  /**
   * On end date change, update the state and emit the new value.
   *
   * @param date The selected end date.
   */
  handleEndDateChange = (date: Date | number): void => {
    this.setState({ ...this.state, form: { ...this.state.form, endDate: Number(date) } }, () => this.props.onFormChange(this.state.form));
  };

  render(): JSX.Element {
    const past24Hours: JSX.Element = (
      <div className="gic-input gic-input__radio">
        <input
          type="radio"
          id={`past-24-hours-${this.state.uuid}`}
          value={StartEndDateRadioOption.PAST_24_HOURS}
          checked={this.state.form.radioValue === StartEndDateRadioOption.PAST_24_HOURS}
          onChange={this.handleRadioInputChange}
        />
        <label htmlFor={`past-24-hours-${this.state.uuid}`}>Past 24 Hours</label>
      </div>
    );

    const past7Days: JSX.Element = (
      <div className="gic-input gic-input__radio">
        <input
          type="radio"
          id={`past-7-days-${this.state.uuid}`}
          value={StartEndDateRadioOption.PAST_7_DAYS}
          checked={this.state.form.radioValue === StartEndDateRadioOption.PAST_7_DAYS}
          onChange={this.handleRadioInputChange}
        />
        <label htmlFor={`past-7-days-${this.state.uuid}`}>Past 7 Days</label>
      </div>
    );

    // Temporarily disabled 30 day option due to how long it takes to get results
    // const past30Days: JSX.Element = (
    //   <div className="gic-input gic-input__radio">
    //     <input
    //       type="radio"
    //       id={`past-30-days-${this.state.uuid}`}
    //       value={StartEndDateRadioOption.PAST_30_DAYS}
    //       checked={this.state.form.radioValue === StartEndDateRadioOption.PAST_30_DAYS}
    //       onChange={this.handleRadioInputChange}
    //     />
    //     <label htmlFor={`past-30-days-${this.state.uuid}`}>Past 30 Days</label>
    //   </div>
    // );

    const customDay: JSX.Element = (
      <>
        <div className="gic-input gic-input__radio">
          <input
            type="radio"
            id={`custom-day-${this.state.uuid}`}
            value={StartEndDateRadioOption.CUSTOM_DAY}
            checked={this.state.form.radioValue === StartEndDateRadioOption.CUSTOM_DAY}
            onChange={this.handleRadioInputChange}
          />
          <label htmlFor={`custom-day-${this.state.uuid}`}>Custom Day</label>
        </div>

        {this.state.form.radioValue === StartEndDateRadioOption.CUSTOM_DAY && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="date-time-group">
              <KeyboardDatePicker
                autoOk
                id="date"
                variant="inline"
                className="gic-input gic-input--inline"
                label="Date"
                format="MM/dd/yyyy"
                value={this.state.form.startDate}
                onChange={this.handleCustomDateChange}
              />
            </div>
          </MuiPickersUtilsProvider>
        )}
      </>
    );

    const customRange: JSX.Element = (
      <>
        <div className="gic-input gic-input__radio">
          <input
            type="radio"
            id={`custom-range-${this.state.uuid}`}
            value={StartEndDateRadioOption.CUSTOM_RANGE}
            checked={this.state.form.radioValue === StartEndDateRadioOption.CUSTOM_RANGE}
            onChange={this.handleRadioInputChange}
          />
          <label htmlFor={`custom-range-${this.state.uuid}`}>Custom Range</label>
        </div>

        {this.state.form.radioValue === StartEndDateRadioOption.CUSTOM_RANGE && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="date-time-group">
              <KeyboardDateTimePicker
                autoOk
                id="startDate"
                variant="inline"
                className="gic-input gic-input--inline"
                label="Start Date"
                format="MM/dd/yyyy hh:mm:ss"
                value={this.state.form.startDate}
                onChange={this.handleStartDateChange}
              />
            </div>
            <div className="date-time-group">
              <KeyboardDateTimePicker
                autoOk
                id="endDate"
                variant="inline"
                className="gic-input gic-input--inline"
                label="End Date"
                format="MM/dd/yyyy hh:mm:ss"
                value={this.state.form.endDate}
                onChange={this.handleEndDateChange}
              />
            </div>
          </MuiPickersUtilsProvider>
        )}
      </>
    );

    return (
      <div className="gic-form__field-group">
        <h3>Time / Date Range</h3>
        {past24Hours}
        {past7Days}
        {/* {past30Days} */}
        {customDay}
        {customRange}
      </div>
    );
  }
}
