import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';
import { Color, FontSize, FontWeight } from '../../styling';

export const styling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .gic-line-chart {
    background-color: ${theme.component.background};
    height: 100%;
    transition: 0.5s;
    width: 100%;

    &__svg {
      display: block;
      width: 100%;
    }

    &__container {
      display: block;
      width: 100%;
    }

    &__line {
      fill: none;
      stroke: ${Color.Blue};
      stroke-width: 2px;
    }

    &__axis-label {
      fill: ${theme.text};
    }

    &__focus {
      &-overlay {
        fill: none;
        pointer-events: all;
      }

      &-tooltip {
        &-background {
          fill: ${Color.Purple};
        }

        &-triangle {
          fill: ${Color.Purple};
        }

        &-text {
          fill: ${Color.White};
          font-size: ${FontSize.SM};
        }

        &-label {
          font-weight: ${FontWeight.Bold};
        }
      }
    }
  }
`;
