import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { Spacing } from '../../styling';

export const styling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .download-form {
    padding: ${Spacing.LG};

    .available-stations {
      max-height: 200px;
      overflow: auto;

      & > * {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .is-download-one-file {
      margin-top: ${Spacing.MD};
    }

    .toggle-all-stations-button {
      margin-left: ${Spacing.SM};
    }

    .download-csv-button {
      display: block;
      margin: 0;
      min-width: 150px;
      text-decoration: none;

      &-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: ${Spacing.MD};
      }
    }

    .date-time-group {
      display: flex;
      margin: ${Spacing.SM};
    }
  }
`;
