import logo from '../../images/cpi-logo.png';

/*
{
  "$schema": "https://json-schema.org/draft/2020-12/schema",
  "title": "Magstar Application Settings",
  "type": "object",
  "properties": {
    "includeTosCheckbox": {
      "type": "boolean",
      "description": "Include a terms of service checkbox for logging in.",
      "default": false
    },
    "tosUrl": {
      "type": "string",
      "description": "A URL to the terms of service document.",
      "default": ""
    },
    "logoUrl": {
      "type": "string",
      "description": "A URL for the logo used on the login page. If empty, just load the included logo.",
      "default": ""
    },
    "logoAltText": {
      "type": "string",
      "description": "The alt. text to include with the logo image on the login page.",
      "default": "CPI Logo"
    },
    "installationMapUrl": {
      "type": "string",
      "description": "A URL to an image of a map of all of the installations that report to this Magstar instance. If empty or missing, there is no installation map, so don't include it as a page",
      "default": ""
    }
  },
  "required": []
}
*/
export interface Settings {
  tosUrl: string;
  logoUrl: any;
  logoAltText: string;
  includeTosCheckbox: boolean;
  installationMapUrl?: string;
}

export const DEFAULT_SETTINGS: Settings = {
  tosUrl: '/tos.pdf',
  logoUrl: logo,
  logoAltText: 'CPI Logo',
  includeTosCheckbox: true,
};
