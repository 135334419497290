import { BehaviorSubject, Observable, of } from 'rxjs';
import { expand, reduce, takeWhile } from 'rxjs/operators';

import {
  ApiService,
  GetStationMeasurementsByIdParams,
  MagstarMeasurement,
  MagstarMeasurementResult,
  MagstarShortStationInfo,
} from '../api';
import { Utils } from '../../shared';
import { cloneDeep } from 'lodash';
import { DateService, TimeFormat } from '../date';

export enum DataMode {
  STREAMING = 'streaming',
  HISTORIC = 'historic',
}

/**
 * STATIONS SERVICE
 *
 * Keeps track of:
 *  - the list of available stations (used to generate the left-hand navigation)
 *  - the mode (historic vs streaming)
 *  - whether or not streaming mode is paused
 * And provides a way to update the list of available stations and toggle between the modes and isPaused flag.
 */
export class StationsService {
  private readonly _defaultDataMode: DataMode = DataMode.STREAMING;
  private readonly _defaultIsPaused: boolean = false;

  static instance: StationsService;
  static getInstance(): StationsService {
    if (!StationsService.instance) {
      StationsService.instance = new StationsService();
    }
    return StationsService.instance;
  }

  /**
   * The array of available stations.
   * Primarily used to generate the left-hand navigation.
   */
  private _stations$: BehaviorSubject<MagstarShortStationInfo[]> = new BehaviorSubject<MagstarShortStationInfo[]>([]);
  get stations$(): Observable<MagstarShortStationInfo[]> {
    return this._stations$.asObservable();
  }
  get stations(): MagstarShortStationInfo[] {
    return this._stations$.value;
  }
  set stations(stations: MagstarShortStationInfo[]) {
    this._stations$.next(stations || []);
  }

  /**
   * The data mode. Dictates if the data displayed is historical (static) or streaming (live polling).
   */
  private _mode$: BehaviorSubject<DataMode> = new BehaviorSubject<DataMode>(this._defaultDataMode);
  get mode$(): Observable<DataMode> {
    return this._mode$.asObservable();
  }
  get mode(): DataMode {
    return this._mode$.value;
  }

  /**
   * Used to help dictate if the app is actively polling while in streaming mode.
   */
  private _isPaused$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this._defaultIsPaused);
  get isPaused$(): Observable<boolean> {
    return this._isPaused$.asObservable();
  }
  get isPaused(): boolean {
    return this._isPaused$.value;
  }

  /**
   * Toggles the data mode between STREAMING and HISTORIC.
   */
  toggleDataMode = (): void => {
    const newMode: DataMode = this.mode === DataMode.STREAMING ? DataMode.HISTORIC : DataMode.STREAMING;

    if (newMode === DataMode.HISTORIC) {
      const dateService: DateService = DateService.getInstance();

      dateService.updateTime(
        Utils.calculateStartEndTime(
          dateService.selectedRadioButton,
          { startTime: dateService.startTime, endTime: dateService.endTime },
          dateService.timeFormat === TimeFormat.UTC,
        ),
      );
    }

    this._mode$.next(newMode);
  };

  /**
   * Toggles the isPaused flag.
   */
  toggleIsPaused = (): void => {
    this._isPaused$.next(!this.isPaused);
  };

  /**
   * Recursive function that calls /stations/{station_id}/measurements until all the measurements for a given station between the provided
   * after_ts and before_ts options.
   *
   * @param id The id of the station.
   * @param parameters The (optional) parameters used to filter the measurements grabbed.
   * @returns The station measurements for a given station based on the provided parameters.
   */
  getStationMeasurementsById = (id: number, parameters?: GetStationMeasurementsByIdParams): Observable<MagstarMeasurement[]> => {
    const apiService: ApiService = ApiService.getInstance();
    const newParameters: GetStationMeasurementsByIdParams = parameters || {};

    return apiService.getStationMeasurementsById(id, cloneDeep(newParameters)).pipe(
      expand((measurement: MagstarMeasurementResult) => {
        const hasMoreData: boolean = measurement && measurement.has_further_data && !!measurement.next_ts;

        if (hasMoreData) {
          newParameters[newParameters.reverse_order ? 'before_ts' : 'after_ts'] = measurement.next_ts;
          return apiService.getStationMeasurementsById(id, cloneDeep(newParameters));
        }

        return of(null);
      }),
      takeWhile((response: MagstarMeasurementResult | null) => !!response),
      reduce((acc: MagstarMeasurement[], result: MagstarMeasurementResult) => acc.concat(result.measurements), []),
    );
  };
}
