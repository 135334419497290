import React, { Component } from 'react';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import {
  axisBottom,
  axisLeft,
  bisector,
  easeLinear,
  Line,
  line,
  max,
  min,
  pointer,
  ScaleLinear,
  scaleLinear,
  ScaleTime,
  scaleTime,
  select,
} from 'd3';
import { cloneDeep, isEqual } from 'lodash';
import { ISimplifyObjectPoint, Simplify } from 'simplify-ts';
import { v4 as uuidv4 } from 'uuid';

import { DataMode, DateService, MagstarMeasurement, StationsService, TimeFormat } from '../../services';
import { Utils } from '../../shared';
import { LineChartProps, LineChartSizes, LineChartState, XYData, XYScales } from './line-chart.component.type';

export class LineChartComponent extends Component<LineChartProps, LineChartState> {
  readonly bisectDate: (array: ArrayLike<unknown>, x: Date, lo?: number, hi?: number) => number = bisector(
    (d: MagstarMeasurement) => d.timestamp,
  ).left;

  constructor(props: LineChartProps) {
    super(props);

    this.state = {
      unsubscribe$: new Subject<void>(),
      uuid: uuidv4(),
      container: React.createRef(),
      timeFormat: DateService.getInstance().timeFormat,
      isPaused: StationsService.getInstance().isPaused,
      formattedData: [],
    };
  }

  /**
   * On component mount:
   */
  componentDidMount(): void {
    this.createSubscriptions();
    window.addEventListener('resize', () => setTimeout(this.updateLineChart, 500));

    this.setState({ ...this.state, formattedData: this.formatData() }, () => {
      this.createLineChart();
    });
  }

  /**
   * When component properties are updated, update the table.
   *
   * @param prevProps The new table properties.
   */
  componentDidUpdate(prevProps: LineChartProps): void {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ ...this.state, formattedData: this.formatData() }, () => {
        this.updateLineChart();
      });
    }
  }

  /**
   * On component unmount, complete any active subscriptions.
   */
  componentWillUnmount(): void {
    Utils.completeSubject(this.state.unsubscribe$);
  }

  /**
   * Creates a subscription to
   *  - the DateService time format so that when it changes it updates the formatting of the times
   *    in the tooltip and long the x-axis tick labels.
   *  - the StationService so that when the mode changes the position of the tooltip gets corrected.
   */
  createSubscriptions = (): void => {
    combineLatest([StationsService.getInstance().isPaused$, DateService.getInstance().timeFormat$])
      .pipe(takeUntil(this.state.unsubscribe$), debounceTime(0), distinctUntilChanged())
      .subscribe(([isPaused, timeFormat]: [boolean, TimeFormat]) =>
        this.setState({ ...this.state, timeFormat, isPaused }, () => this.updateLineChart()),
      );
  };

  /**
   * Calculates the desired widths, heights, and margins of various parts of the line chart.
   *
   * @returns The calculated widths, heights, and margins.
   */
  getSizes = (): LineChartSizes => {
    const margin = { top: 20, bottom: 70, left: 100, right: 70 };
    const width = (this.props.width || this.state.container?.current?.offsetWidth) - margin.left - margin.right;
    const height = (this.props.height || this.state.container?.current?.offsetHeight) - margin.top - margin.bottom;
    const tooltip = { width: 170, height: 150 };
    return { margin, width, height, tooltip };
  };

  /**
   * Formats the provided data (from the data property) to be used in the chart by
   * converting the timestamp date string to a Date object and making sure the h
   * value is actually a number.
   *
   * @returns The formatted data.
   */
  formatData = (): MagstarMeasurement[] => {
    let data: MagstarMeasurement[] = cloneDeep(this.props.data) || [];

    if (data.length > 1000) {
      data = this.simplifyData(data);
    }

    return data.map((d: MagstarMeasurement) => ({
      ...d,
      timestamp: new Date(d.timestamp),
      h: +d.horizontal_field_magnitude,
    }));
  };

  /**
   * Simplifies the data to have less data points to make the chart easier to draw.
   *
   * @param data The data to be simplified.
   * @returns The simplified data.
   */
  simplifyData = (data: MagstarMeasurement[]): MagstarMeasurement[] => {
    const xyData: XYData[] = data.map((measurement: MagstarMeasurement) => ({
      ...measurement,
      oldX: measurement.x,
      oldY: measurement.y,
      x: measurement.timestamp as number,
      y: measurement.horizontal_field_magnitude,
    }));
    const tolerance: number = data.length / (data.length * 5);

    const simplifiedData: ISimplifyObjectPoint[] = Simplify(xyData, tolerance);

    return simplifiedData.map((sData: XYData) => ({
      timestamp: sData.x,
      horizontal_field_magnitude: sData.y,
      x: sData.oldX,
      y: sData.oldY,
      z: sData.z,
      temperature: sData.temperature,
      horizontal_field_angle: sData.horizontal_field_angle,
    }));
  };

  /**
   * Creates the ranges and domains for x and y
   *
   * @param sizes The sizes and spacing of the line chart.
   * @param data The data used to populate the line chart.
   * @returns The x (date) and y (number) scales.
   */
  createScales = (sizes: LineChartSizes, data: MagstarMeasurement[], isLine?: boolean): XYScales => {
    // Set the x domain from earliest timestamp to latest timestamp
    const minX: number = (data[0]?.timestamp as number) || Date.now();
    const maxX: number = (data[data.length - 1]?.timestamp as number) || Date.now();
    const width: number = isLine ? sizes.width + sizes.margin.right : sizes.width;
    const x: ScaleTime<number, number, never> = scaleTime().range([0, width]).domain([minX, maxX]);

    // Set the y range from lowest magnitude - 10% of total range to highest magnitude + 10% of total range
    const minY: number = min(data, (d: MagstarMeasurement) => d.horizontal_field_magnitude);
    const maxY: number = max(data, (d: MagstarMeasurement) => d.horizontal_field_magnitude);
    const tenPercentOfRange: number = Math.abs(maxY - minY) * 0.1;
    const y: ScaleLinear<number, number, never> = scaleLinear()
      .range([sizes.height, 0])
      .domain([minY - tenPercentOfRange, maxY + tenPercentOfRange]);

    return { x, y };
  };

  createLineChart = (): void => {
    const sizes: LineChartSizes = this.getSizes();
    const data: any[] = this.state.formattedData;
    const { x, y } = this.createScales(sizes, data);
    const lineValues: XYScales = this.createScales(sizes, data, true);

    // Define the line
    const valueLine: Line<[number, number]> = line()
      .x((d: any) => lineValues.x(d.timestamp))
      .y((d: any) => y(d.horizontal_field_magnitude));

    /**
     * Append the svg object to the body of the page using the div with the generated uuid
     * appends a 'group' element to 'svg'
     * moves the 'group' element to the top left margin
     */
    const svg = select(`.gic-line-chart--${this.state.uuid}`)
      .append('svg')
      .attr('class', 'gic-line-chart__svg')
      .attr('width', sizes.width + sizes.margin.left + sizes.margin.right)
      .attr('height', sizes.height + sizes.margin.bottom + sizes.margin.top)
      .append('g')
      .attr('class', 'gic-line-chart__container')
      .attr('transform', 'translate(' + sizes.margin.left + ',' + sizes.margin.top + ')');

    // Append the clip path definition
    svg
      .append('defs')
      .append('clipPath')
      .attr('id', `gic-line-chart__clip-path--${this.state.uuid}`)
      .append('rect')
      .attr('width', sizes.width)
      .attr('height', sizes.height);

    // Append the x axis
    svg
      .append('g')
      .attr('class', 'gic-line-chart__axis--x')
      .attr('transform', 'translate(0,' + sizes.height + ')')
      .call(
        axisBottom(x)
          .ticks(5)
          .tickFormat((d: Date) => Utils.formatDate(d.getTime(), this.state.timeFormat, true)),
      );

    // Append the text label for the x axis
    svg
      .append('text')
      .attr('class', 'gic-line-chart__axis-label gic-line-chart__axis-label--x')
      .attr('transform', `translate(${sizes.width / 2}, ${sizes.height + sizes.margin.top + 20})`)
      .style('text-anchor', 'middle')
      .text('Date');

    // Append the y axis
    svg.append('g').attr('class', 'gic-line-chart__axis--y').call(axisLeft(y));

    // Append the text label for the y axis
    svg
      .append('text')
      .attr('class', 'gic-line-chart__axis-label gic-line-chart__axis-label--y')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - sizes.margin.left + 15)
      .attr('x', 0 - sizes.height / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text('Horizontal Field Magnitude (nT)');

    // Add the valueLine path.
    svg
      .append('g')
      .attr('clip-path', `url(#gic-line-chart__clip-path--${this.state.uuid})`)
      .attr('class', 'gic-line-chart__clip-path')
      .append('path')
      .datum(data)
      .attr('class', 'gic-line-chart__line')
      .attr('d', valueLine);

    this.generateFocusAndTooltip();
  };

  /**
   * Updates the line chart.
   * Handles animating polling when adding new points.
   */
  updateLineChart = (): void => {
    const svg = select(`.gic-line-chart--${this.state.uuid}`);

    if (svg) {
      const sizes: LineChartSizes = this.getSizes();
      const data: any[] = this.state.formattedData;
      const { x, y } = this.createScales(sizes, data);
      const lineValues: XYScales = this.createScales(sizes, data, true);

      // define the line
      const valueLine = line()
        .x((d: any) => lineValues.x(d.timestamp))
        .y((d: any) => y(d.horizontal_field_magnitude));

      // Update the width and height of the svg if the window has been resized
      svg
        .attr('width', sizes.width + sizes.margin.left + sizes.margin.right)
        .attr('height', sizes.height + sizes.margin.bottom + sizes.margin.top);

      svg
        .select('.gic-line-chart__axis--x')
        .transition()
        .call(
          axisBottom(x)
            .ticks(5)
            .tickFormat((d: Date) => Utils.formatDate(d.getTime(), this.state.timeFormat, true))
            .bind(this),
        );

      // Update the position of the text label for the x axis
      svg
        .select('.gic-line-chart__axis-label--x')
        .transition()
        .attr('transform', `translate(${sizes.width / 2}, ${sizes.height + sizes.margin.top + 30})`);

      svg.select('.gic-line-chart__axis--y').transition().call(axisLeft(y).bind(this));

      const graphLine = svg.select('.gic-line-chart__line');

      if (graphLine) {
        if (data?.length && StationsService.getInstance().mode === DataMode.STREAMING && !this.state.isPaused) {
          const minX: Date = min(data, (d: MagstarMeasurement) => d.timestamp as Date);

          graphLine
            .attr('d', valueLine)
            .datum(data)
            .attr('transform', null)
            .transition()
            .duration(900)
            .ease(easeLinear)
            .attr('transform', `translate(${x(new Date(minX.getTime() - 900))}, 0)`);
        } else {
          graphLine.attr('d', valueLine).datum(data).attr('transform', null);
        }
      }

      svg.select('.gic-line-chart__focus-overlay').attr('width', sizes.width).attr('height', sizes.height);

      const mousemove = this.createMousemoveFunction(data, lineValues.x, y);
      svg.select('.gic-line-chart__focus-overlay').on('mousemove', mousemove);
    }
  };

  /**
   * Generates and appends the focus area and tooltip to the line chart.
   */
  generateFocusAndTooltip = (): void => {
    const sizes: LineChartSizes = this.getSizes();
    const data: MagstarMeasurement[] = this.state.formattedData;
    const { x, y } = this.createScales(sizes, data, true);
    const svg = select(`.gic-line-chart--${this.state.uuid}`).select('.gic-line-chart__container');

    // append focus container
    const focus = svg.append('g').attr('class', 'gic-line-chart__focus').style('display', 'none');

    const tooltip = focus
      .append('g')
      .attr('class', 'gic-line-chart__focus-tooltip')
      .attr('transform', `translate(${-(sizes.tooltip.width / 2)}, 10)`);

    tooltip
      .append('rect')
      .attr('class', 'gic-line-chart__focus-tooltip-background')
      .attr('width', sizes.tooltip.width)
      .attr('height', sizes.tooltip.height)
      .attr('rx', 4)
      .attr('ry', 4);

    tooltip
      .append('polygon')
      .attr('points', `${sizes.tooltip.width / 2},-10, ${sizes.tooltip.width / 2 - 5},1, ${sizes.tooltip.width / 2 + 5},1`)
      .attr('class', 'gic-line-chart__focus-tooltip-triangle');

    // append date
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-date')
      .attr('x', 10)
      .attr('y', 20);

    // append x value
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-label gic-line-chart__focus-tooltip-label--x')
      .attr('x', 20)
      .attr('y', 50)
      .text('x:');
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-value gic-line-chart__focus-tooltip-value--x')
      .attr('x', 40)
      .attr('y', 50);

    // append y value
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-label gic-line-chart__focus-tooltip-label--y')
      .attr('x', 20)
      .attr('y', 70)
      .text('y:');
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-value gic-line-chart__focus-tooltip-value--y')
      .attr('x', 40)
      .attr('y', 70);

    // append z value
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-label gic-line-chart__focus-tooltip-label--z')
      .attr('x', 20)
      .attr('y', 90)
      .text('z:');
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-value gic-line-chart__focus-tooltip-value--z')
      .attr('x', 40)
      .attr('y', 90);

    // append h value
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-label gic-line-chart__focus-tooltip-label--h')
      .attr('x', 20)
      .attr('y', 110)
      .text('h:');
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-value gic-line-chart__focus-tooltip-value--h')
      .attr('x', 40)
      .attr('y', 110);

    // append angle value
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-label gic-line-chart__focus-tooltip-label--angle')
      .attr('x', 20)
      .attr('y', 130)
      .text('angle:');
    tooltip
      .append('text')
      .attr('class', 'gic-line-chart__focus-tooltip-text gic-line-chart__focus-tooltip-value gic-line-chart__focus-tooltip-value--angle')
      .attr('x', 65)
      .attr('y', 130);

    const mousemove = this.createMousemoveFunction(data, x, y);

    svg
      .append('rect')
      .attr('clip-path', `url(#gic-line-chart__clip-path--${this.state.uuid})`)
      .attr('class', 'gic-line-chart__focus-overlay')
      .attr('width', sizes.width)
      .attr('height', sizes.height)
      .on('mouseover', () => focus.style('display', null))
      .on('mouseout', () => focus.style('display', 'none'))
      .on('mousemove', mousemove);
  };

  /**
   * Creates the mousemove function used to update and position the popover tooltip based on the pointer location.
   * If the horizontal_field_magnitude value is below the median value, display the tooltip above the line; otherwise, above the line.
   *
   * @param data The data used to populate the line chart.
   * @param x The x scale (date).
   * @param y The y scale (number).
   * @returns A mouseEvent function that returns nothing.
   */
  createMousemoveFunction = (
    data: MagstarMeasurement[],
    x: ScaleTime<number, number, never>,
    y: ScaleLinear<number, number, never>,
  ): ((event: MouseEvent) => void) => {
    const sizes: LineChartSizes = this.getSizes();

    return (event: MouseEvent) => {
      if (data?.length) {
        const svg = select(`.gic-line-chart--${this.state.uuid}`);
        const focus = svg.select('.gic-line-chart__focus');

        const x0: Date = x.invert(pointer(event, svg.select('.gic-line-chart__focus-overlay').node())[0]);
        const i: number = this.bisectDate(data, x0, 1);
        const d0: MagstarMeasurement = data[i - 1];
        const d1: MagstarMeasurement = data[i];
        const d: MagstarMeasurement =
          x0?.getTime() - (d0?.timestamp as Date)?.getTime() > (d1?.timestamp as Date)?.getTime() - x0?.getTime() ? d1 : d0;

        focus.attr('transform', `translate(${x(d.timestamp)}, ${y(d.horizontal_field_magnitude)})`);

        const xMax: number = max(data, (d: MagstarMeasurement) => d.horizontal_field_magnitude);
        const xMin: number = min(data, (d: MagstarMeasurement) => d.horizontal_field_magnitude);
        const xRange: number = Math.abs(xMax - xMin);
        const xNewMin: number = Math.abs(d.horizontal_field_magnitude - xMin);
        const isBelowHalf: boolean = xNewMin / xRange < 0.5;
        const tooltipYPosition: number = isBelowHalf ? -(sizes.tooltip.height + 10) : 10;

        focus.select('.gic-line-chart__focus-tooltip').attr('transform', `translate(${-(sizes.tooltip.width / 2)}, ${tooltipYPosition})`);
        focus.select('.gic-line-chart__focus-tooltip-triangle').attr(
          'points',
          `
            ${sizes.tooltip.width / 2},${isBelowHalf ? sizes.tooltip.height + 10 : -10},
            ${sizes.tooltip.width / 2 - 5},${isBelowHalf ? sizes.tooltip.height - 1 : 1},
            ${sizes.tooltip.width / 2 + 5},${isBelowHalf ? sizes.tooltip.height - 1 : 1}
          `,
        );

        focus.select('.gic-line-chart__focus-tooltip-date').text(Utils.formatDate((d.timestamp as Date).getTime(), this.state.timeFormat));
        focus.select('.gic-line-chart__focus-tooltip-value--x').text(Utils.roundToSingleDecimal(d.x) || '-');
        focus.select('.gic-line-chart__focus-tooltip-value--y').text(Utils.roundToSingleDecimal(d.y) || '-');
        focus.select('.gic-line-chart__focus-tooltip-value--z').text(Utils.roundToSingleDecimal(d.z) || '-');
        focus.select('.gic-line-chart__focus-tooltip-value--h').text(Utils.roundToSingleDecimal(d.horizontal_field_magnitude) || '-');
        focus.select('.gic-line-chart__focus-tooltip-value--angle').text(Utils.roundToSingleDecimal(d.horizontal_field_angle) || '-');
      }
    };
  };

  render(): JSX.Element {
    return <div ref={this.state.container} className={`gic-line-chart gic-line-chart--${this.state.uuid}`}></div>;
  }
}
