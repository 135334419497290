import { BehaviorSubject, Observable } from 'rxjs';
import { StartEndDateRadioOption } from '../../components/start-end-date-form/start-end-date-form.component';

export enum TimeFormat {
  UTC = 'UTC',
  LOCAL = 'Local',
}

/**
 * DATE SERVICE
 *
 * The Date Service keeps track of:
 *  - the start time (used for both streaming and historic mode).
 *  - the end time (used for historic mode)
 *  - the format of the date/ time displayed throughout the application.
 */
export class DateService {
  private readonly _localStorageTimeFormatKey = 'gic-time-format';
  private readonly _defaultTimeFormat: TimeFormat = TimeFormat.UTC;

  static instance: DateService;
  static getInstance(): DateService {
    if (!DateService.instance) {
      DateService.instance = new DateService();
    }
    return DateService.instance;
  }

  /**
   * The selected radio button option.
   * Used to set the "default" values for the historic and download menus.
   */
  private _selectedRadioButton: StartEndDateRadioOption = StartEndDateRadioOption.PAST_24_HOURS;
  get selectedRadioButton(): StartEndDateRadioOption {
    return this._selectedRadioButton;
  }
  set selectedRadioButton(value: StartEndDateRadioOption) {
    this._selectedRadioButton = value;
  }

  /**
   * The start time of the data displayed.
   * It is the only time used if in streaming mode.
   */
  private _startTime$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  get startTime$(): Observable<number> {
    return this._startTime$.asObservable();
  }
  get startTime(): number {
    return this._startTime$.value;
  }

  /**
   * The end time of the data displayed.
   * It is used with the start time if in historic mode.
   */
  private _endTime$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  get endTime$(): Observable<number> {
    return this._endTime$.asObservable();
  }
  get endTime(): number {
    return this._endTime$.value;
  }

  /**
   * The format of the time dates in the application are displayed.
   */
  private _timeFormat$: BehaviorSubject<TimeFormat> = new BehaviorSubject<TimeFormat>(TimeFormat.UTC);
  get timeFormat$(): Observable<TimeFormat> {
    return this._timeFormat$.asObservable();
  }
  get timeFormat(): TimeFormat {
    return this._timeFormat$.value;
  }

  /**
   * On service construction, set the initial time format.
   */
  constructor() {
    this.setSavedTimeFormat();
  }

  /**
   * Toggles the time format between UTC and Local.
   */
  toggleTimeFormat = (): void => {
    this._timeFormat$.next(this.timeFormat === TimeFormat.UTC ? TimeFormat.LOCAL : TimeFormat.UTC);
    localStorage.setItem(this._localStorageTimeFormatKey, this.timeFormat);
  };

  /**
   * Set the time format to the value set in localStorage, if it exists. Otherwise, use the default time format.
   */
  setSavedTimeFormat = (): void => {
    this._timeFormat$.next((localStorage.getItem(this._localStorageTimeFormatKey) as TimeFormat) || this._defaultTimeFormat);
  };

  /**
   * Updates the time based on the provided start and end times.
   *
   * @param times The start and end times of the current data.
   */
  updateTime = (times: { startTime?: number; endTime?: number }): void => {
    if (times) {
      if ('startTime' in times) {
        this._startTime$.next(times.startTime);
      }
      if ('endTime' in times) {
        this._endTime$.next(times.endTime);
      }
    }
  };
}
