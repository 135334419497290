import React, { Component, MouseEvent } from 'react';
import { Subject } from 'rxjs';

import { DateService } from '../../services';
import { Utils } from '../../shared';
import {
  StartEndDateFormComponent,
  StartEndDateOnFormChange,
  StartEndDateRadioOption,
} from '../start-end-date-form/start-end-date-form.component';

export interface HistoricFormState {
  unsubscribe$: Subject<void>;
  form: {
    radioValue: StartEndDateRadioOption;
    startDate: number;
    endDate: number;
  };
}

/**
 * HISTORIC FORM COMPONENT
 */
export class HistoricFormComponent extends Component<unknown, HistoricFormState> {
  private _isMounted = false;

  constructor(props: unknown) {
    super(props);
    this.state = {
      unsubscribe$: new Subject<void>(),
      form: {
        radioValue: DateService.getInstance().selectedRadioButton,
        startDate: DateService.getInstance().startTime,
        endDate: DateService.getInstance().endTime,
      },
    };
  }

  /**
   * On component mount, set _isMounted flag to true.
   */
  componentDidMount(): void {
    this._isMounted = true;
  }

  /**
   * On component unmount:
   *  - set _isMounted flag to false
   *  - complete any active subscriptions
   */
  componentWillUnmount(): void {
    this._isMounted = false;
    Utils.completeSubject(this.state.unsubscribe$);
  }

  /**
   * Sets the radio button, start time, and end time values.
   *
   * @param form The form values.
   */
  handleFormChange = (form: StartEndDateOnFormChange): void => {
    if (this._isMounted) {
      this.setState({ ...this.state, form });
    }
  };

  /**
   * Downloads the CSV of the date for the selected stations for the given time/ date range.
   *
   * @param event The button click event, used to prevent the default 'submit' button behavior.
   */
  apply = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    DateService.getInstance().selectedRadioButton = this.state.form.radioValue;
    DateService.getInstance().updateTime({
      startTime: this.state.form.startDate,
      endTime: this.state.form.endDate,
    });
  };

  render(): JSX.Element {
    return (
      <form className="gic-form historic-form">
        <h2>Historical Data</h2>

        <StartEndDateFormComponent onFormChange={this.handleFormChange}></StartEndDateFormComponent>

        <div className="apply-button-wrapper">
          <button type="submit" onClick={this.apply} className="gic-button apply-button">
            Apply
          </button>
        </div>
      </form>
    );
  }
}
