import { createGlobalStyle } from 'styled-components';

import { styling as controlBarStyling } from '../components/control-bar/control-bar.component.styling';
import { styling as downloadFormStyling } from '../components/download-form/download-form.component.styling';
import { styling as historicFormStyling } from '../components/historic-form/historic-form.component.styling';
import { styling as lineChartStyling } from '../components/line-chart/line-chart.component.styling';
import { styling as loadingStyling } from '../components/loading/loading.component.styling';
import { styling as notificationStyling } from '../components/notification/notification.component.styling';
import { styling as sideNavStyling } from '../components/side-nav/side-nav.component.styling';
import { styling as startEndDateFormStyling } from '../components/start-end-date-form/start-end-date-form.component.styling';
import { styling as statusBoxStyling } from '../components/status-box/status-box.component.styling';

import { styling as contentPageStyling } from '../pages/content/content.page.styling';
import { styling as dashboardPageStyling } from '../pages/dashboard/dashboard.page.styling';
import { styling as loginPageStyling } from '../pages/login/login.page.styling';
import { styling as stationPageStyling } from '../pages/station/station.page.styling';

import { componentsStyling } from './components.styling';
import { resetStyling } from './reset.styling';

import { Spacing } from './styles.enum';

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => resetStyling(theme)}

  body {
    background: ${({ theme }) => theme.main.background};
    color: ${({ theme }) => theme.text};
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    height: 100vh;
    transition: all 0.25s linear;
  }

  button {
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .section {
    max-width: 1200px;

    & + .section {
      margin-top: ${Spacing.XL};
    }
  }

  .--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .--not-allowed-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .--hidden {
    display: none;
    opacity: 0;
  }


  ${({ theme }) => componentsStyling(theme)}

  // Components

  ${({ theme }) => controlBarStyling(theme)}

  ${({ theme }) => downloadFormStyling(theme)}

  ${({ theme }) => historicFormStyling(theme)}

  ${({ theme }) => lineChartStyling(theme)}

  ${({ theme }) => loadingStyling(theme)}

  ${({ theme }) => notificationStyling(theme)}

  ${({ theme }) => sideNavStyling(theme)}

  ${({ theme }) => statusBoxStyling(theme)}

  ${({ theme }) => startEndDateFormStyling(theme)}

  // Pages

  ${({ theme }) => contentPageStyling(theme)}

  ${({ theme }) => dashboardPageStyling(theme)}

  ${({ theme }) => loginPageStyling(theme)}

  ${({ theme }) => stationPageStyling(theme)}
`;
