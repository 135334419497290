import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';
import { FontSize, Spacing } from '../../styling';

export const styling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .gic {
    &-station {
      &__header {
        &-info {
          display: flex;
          font-size: ${FontSize.SM};

          &-item {
            & + * {
              margin-left: ${Spacing.SM};
            }

            &-text {
              margin-left: ${Spacing.XXS};
            }
          }
        }
      }

      &__line-graph {
        background-color: ${theme.component.background};
        height: 500px;
        width: 100%;

        &-loading-wrapper {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;
