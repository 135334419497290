import React, { Component } from 'react';

import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faInfoCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum NotificationType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

export interface NotificationProps {
  type: NotificationType;
}

/**
 * NOTIFICATION COMPONENT
 */
export class NotificationComponent extends Component<NotificationProps, unknown> {
  constructor(props: NotificationProps) {
    super(props);
  }

  /**
   * Generates the icon based on the provided type property.
   *
   * @returns The generated font awesome icon DOM element.
   */
  generateTypeIcon = (): JSX.Element => {
    let icon: IconDefinition;

    switch (this.props.type) {
      case NotificationType.ERROR:
        icon = faExclamationCircle;
        break;
      case NotificationType.WARNING:
        icon = faExclamationTriangle;
        break;
      case NotificationType.SUCCESS:
        icon = faCheckCircle;
        break;
      case NotificationType.INFO:
      default:
        icon = faInfoCircle;
        break;
    }

    return (
      <FontAwesomeIcon
        icon={icon}
        className={`gic-notification__icon gic-notification__icon--${this.props.type || NotificationType.INFO}`}></FontAwesomeIcon>
    );
  };

  render(): JSX.Element {
    return (
      <div className={`gic-notification gic-notification--${this.props.type || NotificationType.INFO}`}>
        {this.generateTypeIcon()}
        <div className="gic-notification__content">{this.props.children}</div>
      </div>
    );
  }
}
